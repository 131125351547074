import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Custom Styles
import "./AttachmentStyles.css";

export default function AttachmentDelete(props) {
    const [flagged, setFlagged] = useState(false);
    const attachment = props.image;

    const renderDeleteButton = () => {
        if (flagged) {
            return (
                <button className='remove-attachment-button approved-button' onClick={() => {
                    setFlagged(false);
                    props.removeFromDeletion(attachment);
                }}>
                    <i class="fa-solid fa-check"></i>
                </button>
            )
        }

        return (
            <button className='remove-attachment-button denied-button' onClick={() => {
                setFlagged(true);
                props.addToDeletion(attachment);
            }}>
                <i class="fa-solid fa-x"></i>
            </button>
        )
    }

    const renderAttachmentContainer = () => {

        const openInNewTab = (url) => {
            window.open(url, '_blank', 'noreferrer');
        };

        return (
            <div className='attachment-container'>
                <div className='attachment-control-display'>
                    <h2 className='attachment-text header'>
                        {props.image.name} | {(props.image.size / 1024).toFixed(2)}kb
                    </h2>
                </div>

                <div className='attachment-control-button'>
                    <button onClick={() => openInNewTab(props.image.data)} className='remove-attachment-button approved-button'>View <i class="fa-solid fa-eye"></i></button>
                </div>

                <div className='attachment-control-delete-button'>
                    {renderDeleteButton()}
                </div>
            </div>
        )
    }

    return (
        <>
            {renderAttachmentContainer()}
        </>

    )

}