export const EventSponsorshipData = [
    {
        title: "Bronze",
        color: "bronze",
        amount: "$500",
        perks: [
            "Shoutout at event",
            "Your companies name on promotional materials",
            "Early updates about upcoming events throughout the year"
        ],
    },
    {
        title: "Silver",
        color: "silver",
        amount: "$2000",
        perks: [
            "Your companies logo on promotional materials",
            "Ability to request a product be promoted at the event",
            "All perks of previous tiers!"
        ],
    },
    {
        title: "Gold",
        color: "gold",
        amount: "$5000",
        perks: [
            "Your companies logo permanently listed as a sponsor of the event on our website",
            "Ability to send out a representative to promote your company at the event",
            "All perks of previous tiers!"
        ],
    },
    {
        title: "Platinum",
        color: "platinum",
        amount: "$10,000",
        perks: [
            "Event naming rights",
            "Personalized thank you certificate sent to an address of your choosing",
            "All perks of previous tiers!"
        ],
    },
]