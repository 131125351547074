import React, { useState, useLayoutEffect } from "react";

import { Fade } from "react-reveal";
import { TutorialData } from "./TutorialData";

import "./HomeTutorialStyles.css";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


export default function HomeTutorial() {
    const [width, height] = useWindowSize();

    const renderComponent = () => {
        if (width > 680) {
            return (
                <>
                    <Fade>
                        <div className="tutorial">
                            <h1 className="header">Easy and Reliable</h1>
                            <div className="tutorial-container">
                                <Fade delay={200}>
                                    <div className="tutorial-item">
                                        <div className="tutorial-box-container">
                                            <h2 className="header">Getting Help is Simple</h2>
                                            <ul className="tutorial-box">
                                                {TutorialData.map((item, index) => {
                                                    return (
                                                        <Fade bottom delay={(index + 1) * 900}>
                                                            <li className="tutorial-card" key={index}>
                                                                <h3 className="header">Step {index + 1}</h3>
                                                                <p>{item.info}</p>
                                                            </li>
                                                        </Fade>
                                                    )
                                                }
                                                )}
                                            </ul>
                                        </div>

                                        <Fade bottom delay={3600}>
                                            <div className="tutorial-welcome-card">
                                                <h2 className="header">Welcome to the Community</h2>
                                                <p>We're constantly looking for new ways to make peoples lives better.</p>
                                                <p>By signing up and joining the Hearts Unite community, you'll get the option of learning about new events and ways to help support those in need.</p>
                                                <form action='/apply'>
                                                    <button className="approved-button" action='submit'>Apply for Assistance <i class="fa-solid fa-hand-holding-heart"></i></button>
                                                </form>

                                                <p>Or</p>

                                                <form action='/login'>
                                                    <button className="approved-button" action='submit'>Signup / Login <i class="fa-solid fa-right-to-bracket"></i></button>
                                                </form>
                                            </div>
                                        </Fade>

                                    </div>
                                </Fade>

                                <div className="tutorial-image-container">
                                    <img className="tutorial-image" alt="hugs members standing" src="/images/people-standing-clear.png" />
                                </div>

                            </div>
                        </div>

                    </Fade>
                </>
            )
        }

        return (
            <>
                <>
                    <Fade>
                        <div className="tutorial">
                            <h1 className="header">Welcome To The Community</h1>
                            <p>We're constantly looking for new ways to make peoples lives better.</p>
                            <p>By signing up and joining the Hearts Unite community, you'll get the option of learning about new events and ways to help support those in need.</p>
                            <form action='/apply'>
                                <button className="approved-button" action='submit'>Apply for Assistance <i class="fa-solid fa-hand-holding-heart"></i></button>
                            </form>

                            <p>Or</p>

                            <form action='/login'>
                                <button className="approved-button" action='submit'>Signup / Login <i class="fa-solid fa-right-to-bracket"></i></button>
                            </form>
                            <div className="tutorial-image-container">
                                <img className="tutorial-image" alt="hugs members standing" src="/images/people-standing-clear.png" />
                            </div>

                        </div>
                    </Fade>
                </>
            </>
        )
    }

    return (
        <>
            {renderComponent()}
        </>
    )
}