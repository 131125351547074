export const SponsorsData = [
    {
        title: "Rare Patient Voice",
        alt: "rare patient voice logo",
        source: "/images/sponsor-1.jpg",
    },
    {
        title: "EJ Pretty",
        alt: "ej pretty logo",
        source: "/images/sponsor-2.png",
    },
    {
        title: "Paragon Healthcare",
        alt: "paragon healthcare logo",
        source: "/images/sponsor-3.png",
    },
    {
        title: "Amazon Smile",
        alt: "amazon smile logo",
        source: "/images/sponsor-4.png",
    },
    {
        title: "Octapharma",
        alt: "Octapharma logo",
        source: "/images/sponsor-5.png",
    },
    {
        title: "Bayer",
        alt: "bayer logo",
        source: "/images/sponsor-6.png",
    },
    {
        title: "Sockathon",
        alt: "sockathon logo",
        source: "/images/sponsor-7.png"
    },
    {
        title: "Novo Nordisk",
        alt: "Novo Nordisk logo",
        source: "/images/sponsor-8.png"
    },
    {
        title: "Sanofi",
        alt: "Sanofi logo",
        source: "/images/sanofi-logo-2022.png"
    },
    {
        title: "UNREAL",
        alt: "unreal logo",
        source: "/images/unreal-logo.png"
    }

]