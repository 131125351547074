import React, { useState } from "react";
import axios from "axios";
import { Password } from "primereact/password";
import AttachmentContainer from "../../General/AttachmentContainer";

const main = 0;
const verify = 1;

export default function MainPictureChange(props) {
    // States
    const [showing, setShowing] = useState(main);
    const [updated, setUpdated] = useState(false);
    const [key, setKey] = useState("");
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [mainImg, setMainImg] = useState(null);
    const [pendingMainImage, setPendingMainImage] = useState(null);
    const [pendingFile, setPendingFile] = useState(null);
    const [attachmentError, setAttachmentError] = useState(false);

    // Events

    const onPendingDelete = () => {
        setPendingFile(null);
    }

    const onFileDelete = () => {
        setMainImg(null);
    }

    const onFileAttach = () => {
        if (pendingFile != null) {
            setMainImg(pendingFile);
            setPendingFile(null);
        }
    }

    const onFileUpload = (event) => {
        setPendingFile(event.target.files[0]);
        event.target.value = null;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mainImg) {
            const formData = new FormData();
            formData.append('mainImg', mainImg);
            formData.append('key', key);
            formData.append('id', props.event._id);

            axios.post('/api/update-main-event-image', formData, {
                withCredentials: true,
            }).then((res) => {
                if (res.data.status) {
                    setError(false);
                    setUpdated(true);
                    props.event.mainImage = mainImg;
                } else {
                    setError(true);
                    setErrorMsg(res.data.message);
                }
            })
        }
    }

    // Renders

    const renderMainImageInfo = () => {
        if (mainImg) {
            return (
                <div>
                    <AttachmentContainer attachment={mainImg}
                        pending={false} onPendingDelete={onPendingDelete}
                        onFileDelete={onFileDelete}
                    />
                </div>
            )

        }

        if (pendingFile) {
            return (
                <>
                    {renderPendingFile()}
                </>
            )

        }
    }

    const renderPendingFile = () => {
        if (pendingFile) {
            return (
                <>
                    <div>
                        <h1 className="header">Pending Attachment</h1>
                        <AttachmentContainer attachment={pendingFile}
                            pending={true} onPendingDelete={onPendingDelete}
                            onFileDelete={onFileDelete}
                        />
                    </div>
                </>
            )
        } else {
            return <h3>Nothing Here!</h3>
        }
    }

    const renderSubmitButton = () => {
        if (mainImg) {
            return <button className="add-submit-button approved-button" onClick={() => setShowing(verify)} >Verify & Submit</button>
        }

        return <button className="add-submit-button approved-button" onClick={() => { setError(true); setErrorMsg("No File Attached!") }} >Verify & Submit</button>
    }

    const renderError = () => {
        if (error) {
            return <h3 className="error">{errorMsg}</h3>
        }
    }

    const renderComponent = () => {
        if (showing === main) {
            return (
                <>
                    <h1 className="header">Change Main Image</h1>
                    <h2>Current Image</h2>
                    <h3>{props.event.mainImage.name}</h3>
                    <div className="event-image-change">
                        <img src={props.event.mainImage.data} alt='main event image' />
                    </div>

                    <div className="section-container">
                        <h1 className="header">Upload New Main Event Image</h1>
                        <p>The main event image can be something like a logo, a picture of the location, or anything else you find appropriate.</p>
                        <p>This image will be displayed beside whatever text you put as the description, so choose wisely!</p>
                        {renderMainImageInfo()}
                        <div className="attachment-button-row">

                            <label onChange={onFileUpload} htmlFor="formId">
                                <input name="" type="file" id="formId" hidden />
                                <div className="attachment-button approved-button">
                                    Add File
                                </div>
                            </label>

                            {attachmentError ? <h2>Error Submitting, Try Again or Contact Support</h2> : ""}

                            <div>
                                <button className="approved-button"
                                    onClick={onFileAttach}>Attach File</button>
                            </div>
                        </div>
                        {renderError()}
                        {renderSubmitButton()}
                    </div>
                </>
            )
        }

        return (
            <>
                <h1 className="header">Verify Information</h1>
                <h2>Changing Image from {props.event.mainImage.name} to {mainImg.name}</h2>
                <div className='response-password-input-container'>
                    <label>
                        Input Employee Key to Verify: {key.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                        <Password type="password" name="key" value={key} onChange={(event) => setKey(event.target.value)} toggleMask={true} />
                    </label>
                </div>
                <div className='response-buttons'>
                    <div className='response-buttons'>
                        <div>
                            <button className='denied-button' onClick={() => setShowing(main)}>Go Back</button>
                        </div>
                        <div>
                            <button className='approved-button' onClick={handleSubmit}>Submit</button>
                        </div>

                    </div>
                </div>
            </>
        )
    }

    if (updated) {
        return (
            <>
                <h1 className="header">Main Event Image Changed!</h1>
                <p>The main event image has been changed as requested and will be reflected on the main website immediately.</p>
            </>
        )
    }

    return (
        <>
            {renderComponent()}
        </>
    )
}