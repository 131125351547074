import React from "react";

import { Fade } from 'react-reveal';

import "./TestimonialStyles.css";

export default function Testimonial(props) {

    return (
        <>
            <Fade>
                <div className="testimonial">
                    <div className="testimonial-item">
                        <img src={props.src} alt={props.alt}></img>
                    </div>

                    <div className="testimonial-item">
                        <h2>{props.text}</h2>
                    </div>
                </div>
            </Fade>

        </>
    )
}
