import React, { Component } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

class UserDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownShowing: false,
        }
        axios.defaults.withCredentials = true;
    }

    toggleDropdown = () => {
        this.setState({ dropdownShowing: !this.state.dropdownShowing });
    }

    signoutUser = () => {
        axios.post('/api/signout', { withCredentials: true })
            .then((res) => {
                if (res.status) {
                    // Refresh Page
                    window.location.reload(false);
                }
            })
            .catch((err) => {
            })
    }

    renderDropdown() {
        if (this.state.dropdownShowing) {
            if (this.props.mobile) {
                return (
                    <>
                        <div>
                            <form action="/dashboard">
                                <button action="submit" className="nav-links navbar-button">Dashboard</button>
                            </form>
                        </div>

                        <div>
                            <button onClick={this.signoutUser} className="nav-links navbar-button">Signout</button>
                        </div>
                    </>
                )
            }
            return (
                <>
                    <div>
                        <form action="/dashboard">
                            <button action="submit" className="dashboard-button-clicked">Dashboard</button>
                        </form>
                    </div>

                    <div>
                        <button onClick={this.signoutUser} className="dashboard-button-clicked">Signout</button>
                    </div>
                </>
            )
        } else {
            return <></>;
        }
    }

    render() {
        if (this.props.mobile) {
            return (
                <>
                    <div className="highlight-link">
                        <button onClick={this.toggleDropdown} className="nav-links navbar-button">
                            {this.props.fName} <i class="fa-solid fa-user"></i>
                            {this.state.dropdownShowing ? <i class="fa-solid fa-angle-up" /> : <i class="fa-solid fa-angle-down" />}
                        </button>

                        <div>
                            {this.renderDropdown()}
                        </div>
                    </div>
                </>
            )
        }
        return (
            <>
                <div className="dropdown-button">
                    <button onClick={this.toggleDropdown} className="dashboard-button">
                        {this.props.fName} <i class="fa-solid fa-user"></i>
                        {this.state.dropdownShowing ? <i class="fa-solid fa-angle-up" /> : <i class="fa-solid fa-angle-down" />}
                    </button>
                </div>

                <div className="dashboard-dropdown">
                    {this.renderDropdown()}
                </div>

            </>
        )
    }
}

export default UserDropdown;