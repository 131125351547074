import React, { useState, useLayoutEffect } from "react";

import UserDropdown from "./UserDropdown";

import { MenuData } from "./MenuData";
import "./NavbarStyles.css";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function Navbar(props) {
    const [clicked, setClicked] = useState(false);
    const [width, height] = useWindowSize();

    const handleClick = () => {
        setClicked(!clicked);
    }

    // Returns dashboard button populated by userData if userData exists, otherwise returns login / signup
    const renderUserButtons = () => {
        if (!props.loading) {
            if (props.userData) {
                return (
                    <UserDropdown fName={props.userData.firstName} lName={props.userData.lastName} mobile={false}
                    />)
            } else {
                return (
                    <a href="login" className="nav-login-button"> Signup / Login <i className="fa-solid fa-right-to-bracket" /></a>)
            }
        } else {
            return <a href="" className="nav-login-button">Loading... <i class="fa-solid fa-spinner"></i></a>;
        }

    }

    const renderMobileUserButtons = () => {
        if (!props.loading) {
            if (props.userData) {
                return (
                    <UserDropdown fName={props.userData.firstName} lName={props.userData.lastName} mobile={true}
                    />)
            } else {
                return (
                    <a href="login" className="nav-links highlight-link"><i className="fa-solid fa-right-to-bracket" /> Signup / Login</a>)
            }
        } else {
            return <a href="highlight-link" className="nav-links">Loading... <i class="fa-solid fa-spinner"></i></a>;
        }

    }

    if (width <= 1650) {
        return (
            <>
                <nav className="navbar">
                    <a className="logo-link" href="/"><img src="/images/hugs-w.png" alt="hugs logo" className="hugs-icon"></img></a>
                    <div className={clicked ? "menu-icons clicked" : "menu-icons unclicked"} onClick={handleClick}>
                        <i className={clicked ? "fas fa-x" : "fas fa-bars"}></i>
                    </div>
                    <ul className={clicked ? "nav-menu active" : "nav-menu"}>
                        {renderMobileUserButtons()}
                        {MenuData.map((item, index) => {
                            return (
                                <li key={index}>
                                    <a href={item.url} className={item.cName}>
                                        <i className={item.icon}></i>{item.title}
                                    </a>
                                </li>
                            )
                        })
                        }

                        {/* Login / Signout / Dashboard buttons are handled 
                    seperately from the map function to enable better context handling */}
                    </ul>


                </nav>
            </>
        )
    }

    return (
        <nav className="navbar">
            <a className="logo-link" href="/"><img src="/images/hugs-w.png" alt="hugs logo" className="hugs-icon"></img></a>
            <div className="menu-icons" onClick={handleClick}>
                <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
            </div>
            <ul className={clicked ? "nav-menu active" : "nav-menu"}>
                {MenuData.map((item, index) => {
                    return (
                        <li key={index}>
                            <a href={item.url} className={item.cName}>
                                <i className={item.icon}></i>{item.title}
                            </a>
                        </li>
                    )
                })
                }

                {/* Login / Signout / Dashboard buttons are handled 
                    seperately from the map function to enable better context handling */}

            </ul>

            {renderUserButtons()}
        </nav>
    )
}