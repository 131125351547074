import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import FindRedirectPrompt from '../components/Find/FindRedirectPrompt';
import FindForm from '../components/Find/FindForm';

import { Fade } from 'react-reveal';

import "./FindStyles.css";

function Find(props) {
    const userData = props.userData;
    const [application, setApplicaton] = useState(false);
    const [promptComplete, setPromptComplete] = useState(false);

    React.useEffect(() => {
        document.title = "Find Application | Hearts Unite";
    }, []);

    const onPromptComplete = () => {
        setPromptComplete(true);
    }

    const onApplicationFound = (app) => {
        setApplicaton(app);
    }

    const renderStatus = () => {
        if (application) {
            return (
                <>
                    <div className='application-status-display'>
                        <button className={application.status === "Approved" ? "application approved-button"
                            : application.status === "Pending" ? "application pending-button" : application.status === "Declined" ? "application declined-button" : ""}>
                            {application.status === "Approved" ? "Your Application Has Been Approved!" : application.status === "Pending" ? "Your Application is Pending!"
                                : application.status === "Declined" ? "Your Application has Been Denied" : ""}</button>
                    </div>
                </>
            )
        }
    }

    // Prompts user if logged in, displays search form if application is null, otherwise displays application stored in memory. 
    // To reset the search form, application must be made null again.
    const renderFindForm = () => {
        if (userData && !promptComplete) {
            return (
                <>
                    <Fade>
                        <div className='page-header'>
                            <h1>Find Application by Token</h1>
                        </div>
                    </Fade>
                    <FindRedirectPrompt fName={userData.firstName} onPromptComplete={onPromptComplete} />
                </>
            )

        }

        if (!application) {
            return (
                <>
                    <Fade>
                        <div className='page-header'>
                            <h1>Find Application by Token</h1>
                        </div>
                    </Fade>
                    <FindForm onApplicationFound={onApplicationFound} />
                </>
            )
        }

        return (
            <>
                <div className="find-review-container">
                    <div className='back-button'>
                        <button className='denied-button' onClick={() => setApplicaton(false)}><i class="fa-solid fa-left-long"></i> Go Back</button>
                    </div>
                    {renderStatus()}
                    <div className="review-section">
                        <h2 className='header'>General</h2>
                        <div className="review-items">
                            <div className="review-item">
                                <h3 className='header'>First Name: </h3> <p>{application.firstName ? application.firstName : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Last Name: </h3> <p>{application.lastName ? application.lastName : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Email: </h3> <p>{application.email ? application.email : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Birthday: </h3> <p>{application.birthday ? application.birthday : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Phone: </h3> <p>{application.phone ? application.phone : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Income: </h3> <p>{application.income ? application.income : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Gender: </h3> <p>{application.gender ? application.gender : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Relationship Status: </h3> <p>{application.relationshipStatus ? application.relationshipStatus : "N/A"}</p>
                            </div>

                        </div>


                    </div>

                    <div className="review-section">
                        <h2 className='header'>Address Information</h2>

                        <div className="review-items">
                            <div className="review-item">
                                <h3>Street Address: </h3> <p>{application.streetAddress ? application.streetAddress : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Street Address 2: </h3> <p>{application.streetAddress2 ? application.streetAddress2 : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>City: </h3> <p>{application.city ? application.city : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>State: </h3> <p>{application.state ? application.state : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>ZIP: </h3> <p>{application.zip ? application.zip : "N/A"}</p>
                            </div>
                        </div>

                    </div>

                    <div className="review-section">
                        <h2 className='header'>Medical Information</h2>

                        <div className="review-items">
                            <div className="review-item">
                                <h3>Bleeding Disorder: </h3> <p>{application.bleedingDisorder ? "Yes" : "No"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Relationship to Bleeding Disorder: </h3> <p>{application.bleedingDisorder ? application.bleedingDisorderRelationship : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Type: </h3> <p>{application.bleedingDisorder ? application.bleedingDisorderType : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Severity: </h3> <p>{application.bleedingDisorder ? application.bleedingDisorderSeverity : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>HTC: </h3> <p>{application.bleedingDisorder ? application.HTC : "N/A"}</p>
                            </div>
                        </div>
                    </div>

                    <div className="review-section">
                        <h2 className='header'>Request Information</h2>

                        <div className="review-items">
                            <div className="review-item">
                                <h3>Type of Assistance: </h3> <p>{application.type ? application.type : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Method of Assistance: </h3> <p>{application.method ? application.method : "N/A"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Amount Requested (USD): </h3> <p>{application.amount ? application.amount : "N/A"}</p>
                            </div>
                        </div>

                        <h3>Reason for Applying:</h3>
                        <p>{application.details}</p>
                    </div>

                    <div className="review-section">
                        <h2 className='header'>Attachments</h2>

                        <p>Unable to view without login</p>

                    </div>

                    <div className="review-section">
                        <h2 className='header'>Referral Information</h2>

                        <div className="review-items">
                            <div className="review-item">
                                <h3>Referred: </h3> <p>{application.referred ? "Yes" : "No"}</p>
                            </div>

                            <div className="review-item">
                                <h3>Who Referred You?: </h3> <p>{application.referred ? application.referrer : "N/A"}</p>
                            </div>

                        </div>
                    </div>

                </div>
            </>
        )

    }


    return (
        <>
            <div className='find'>
                <Helmet>
                    <title>Find | Hearts Unite</title>
                    {/* Limits User Zoom */}
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                    <meta name="description" content="Find your application for financial assistance through Hearts Unite to Give Support." />
                    <meta property="og:title" content="Find | Hearts Unite" />
                    <meta property="og:image" content="/images/hugs-logo.png" />
                </Helmet>
                <Fade delay={150}>
                    {renderFindForm()}
                </Fade>
            </div>

        </>

    )
}

export default Find;