import React, { useState, useLayoutEffect } from 'react';

import EmployeeEventAdd from './EmployeeEventAdd';
import EmployeeEventEdit from './EmployeeEventEdit';

import { Fade } from 'react-reveal';

const nothing = 0;
const all = 1;
const active = 2;
const inactive = 3;
const archived = 4;
const add = 5;
const deleteEvent = 6;
const archiveEvent = 7;
const edit = 8;

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function sortByDate(events) {
    events.sort(function (a, b) {
        var partsA = a.date.split('/'),
            partsB = b.date.split('/');
        
        var dateA = new Date(partsA[2], partsA[0] - 1, partsA[1]); // Month is 0-indexed
        var dateB = new Date(partsB[2], partsB[0] - 1, partsB[1]);

        return dateA - dateB;
    });
    return events;
}

function EmployeeEventControl(props) {
    // Local State for Events
    const [events, setEvents] = useState(sortByDate(props.events).reverse());

    const [width, height] = useWindowSize();
    const [showing, setShowing] = useState(nothing);
    const [opened, setOpened] = useState("");
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [editing, setEditing] = useState(false);

    // Update Events
    const updateEvents = (newEvent) => {
        // Update Parent State
        props.setEvents((prevEvents) =>
            prevEvents.map((event) =>
                event._id === newEvent._id ? newEvent : event
            )
        );

        // Update Local State
        setEvents((prevEvents) =>
            prevEvents.map((event) =>
                event._id === newEvent._id ? newEvent : event
            )
        );
    };

    // Events

    const onActiveClick = () => {
        if (showing != active) {
            setShowing(active);
            setOpened("");
        } else {
            setShowing(nothing);
        }

    }

    const onInactiveClick = () => {
        if (showing != inactive) {
            setShowing(inactive);
            setOpened("");
        } else {
            setShowing(nothing);
        }

    }

    const onArchivedClick = () => {
        if (showing != archived) {
            setShowing(archived);
            setOpened("");
        } else {
            setShowing(nothing);
        }

    }

    const onAllClick = () => {
        if (showing != all) {
            setShowing(all);
            setOpened("");
        } else {
            setShowing(nothing);
        }

    }

    const onAddClick = () => {
        if (showing != add) {
            setShowing(add);
            setOpened("");
        } else {
            setShowing(nothing);
        }

    }

    const onDeleteEventClick = () => {
        if (showing != deleteEvent) {
            setShowing(deleteEvent);
            setOpened("");
        } else {
            setShowing(nothing);
        }
    }

    const onArchiveEventClick = () => {
        if (showing != archiveEvent) {
            setShowing(archiveEvent);
            setOpened("");
        } else {
            setShowing(nothing);
        }
    }

    const onEditEventClick = (event) => {
        setSelectedEvent(event);
        setEditing(true);
        setOpened("");
    }

    const onEventClick = (id) => {
        setOpened(id);
    }



    // Renders

    const renderControl = () => {
        if (width <= 1450) {
            return (
                <>
                    <div className='mobile-event-control-row-1'>
                        <button className='approved-button'
                            onClick={onActiveClick}>
                            View Active Events
                        </button>

                        <button className='pending-button'
                            onClick={onInactiveClick}>
                            View Inactive Events
                        </button>

                        <button className='denied-button'
                            onClick={onArchivedClick}>
                            View Archived Events
                        </button>
                    </div>
                    <div className='mobile-event-control-row-2'>
                        <button className='all-button'
                            onClick={onAllClick}>
                            View All Events
                        </button>

                        <button className='search-button'
                            onClick={onAddClick}>
                            Add Event
                        </button>
                    </div>
                </>
            )
        }

        return (
            <div className='event-control'>
                <div className='control-items'>
                    <button className='approved-button'
                        onClick={onActiveClick}>
                        View Active Events
                    </button>

                    <button className='pending-button'
                        onClick={onInactiveClick}>
                        View Inactive Events
                    </button>

                    <button className='denied-button'
                        onClick={onArchivedClick}>
                        View Archived Events
                    </button>

                    <button className='all-button'
                        onClick={onAllClick}>
                        View All Events
                    </button>

                    <button className='search-button'
                        onClick={onAddClick}>
                        Add Event
                    </button>

                </div>
            </div>
        )
    }

    const renderEventItem = (item) => {
        if (opened === item._id) {
            return (
                <>
                    <li className='application-container'>
                        <button className={item.archived ? "application denied" : item.active ? "application approved" : "application pending"}
                            onClick={() => onEventClick('')}>
                            <i class="fa-solid fa-angle-down" />
                            {item.name} | {item.date} | {item.attending} Attending | {item.active ? "Ongoing Event" : "Past Event"}
                        </button>

                        <div className='application-info-list'>
                            <div className='application-info-item'>
                                <h2>Event Name: </h2> <p>{item.name}</p>
                            </div>

                            <div className='application-info-item'>
                                <h2>Event Date: </h2> <p>{item.date}</p>
                            </div>

                            <div className='application-info-item'>
                                <h2>Event Location: </h2> <p>{item.location}</p>
                            </div>

                            <div className='application-info-item'>
                                <h2>Event Added on: </h2> <p>{item.dateAdded}</p>
                            </div>

                            <div className='application-info-item'>
                                <h2>Event Images: </h2> <p>{item.images.length}</p>
                            </div>

                        </div>


                        <div>
                            <h2>Event Description</h2>
                            <p>{item.description}</p>
                        </div>

                        <div>
                            <button className='view-full-button approved-button' onClick={() => onEditEventClick(item)} >
                                View / Change Details
                            </button>
                        </div>

                    </li>

                </>
            )
        } else {
            return (
                <li className='application-container' key={item.token}>
                    <button className={item.archived ? "application denied" : item.active ? "application approved" : "application pending"}
                        onClick={() => onEventClick(item._id)}>
                        <i class="fa-solid fa-angle-right" />
                        {item.name} | {item.date} | {item.attending} Attending | {item.active ? "Ongoing Event" : "Past Event"}
                    </button>
                </li>
            )

        }
    }

    const renderEventsDisplay = () => {
        switch (showing) {
            case nothing:
                if (!events) {
                    return (
                        <>
                            <div>
                                <h2>No Events Here!</h2>
                                <p>Let's add one soon!</p>
                            </div>
                        </>
                    )
                }
                break;

            case all:
                return (
                    <>
                        <ul className='applications-list'>
                            <div className='applications-list-padding'>
                                {events.map((item, index) => {
                                    return (
                                        <Fade delay={(index + 1) * 150}>
                                            {renderEventItem(item)}
                                        </Fade>
                                    )
                                })}
                            </div>
                        </ul>
                    </>
                )

            case active:

                return (

                    <>
                        <ul className='applications-list'>
                            <div className='applications-list-padding'>
                                {events.map((item, index) => {
                                    if (item.active && !item.archived) {
                                        return (
                                            <Fade delay={(index + 1) * 150}>
                                                {renderEventItem(item)}
                                            </Fade>
                                        )
                                    }
                                })}
                            </div>
                        </ul>
                    </>

                )


            case inactive:

                return (

                    <>
                        <ul className='applications-list'>
                            <div className='applications-list-padding'>
                                {events.map((item, index) => {
                                    if (!item.active && !item.archived) {
                                        return (
                                            <Fade delay={(index + 1) * 150}>
                                                {renderEventItem(item)}
                                            </Fade>
                                        )
                                    }
                                })}
                            </div>
                        </ul>
                    </>

                )


            case add:
                return <EmployeeEventAdd />;

            case archived:
                return (
                    <ul className='applications-list'>
                        <div className='applications-list-padding'>
                            {events.map((item, index) => {
                                if (item.archived) {
                                    return (
                                        <Fade delay={(index + 1) * 150}>
                                            {renderEventItem(item)}
                                        </Fade>
                                    )
                                }
                            })}
                        </div>
                    </ul>
                )
        }

    }

    if (editing) {
        return <EmployeeEventEdit setEditing={setEditing} event={selectedEvent} updateEvents={updateEvents}/>
    }

    return (
        <>
            <div className='display-container'>
                <h1 className='header'>Events</h1>
                {renderControl()}
                {renderEventsDisplay()}

            </div>
        </>
    )
}

export default EmployeeEventControl;