import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';

axios.defaults.withCredentials = true;

function VerificationForm() {
    const [code, setCode] = useState("");
    let navigate = useNavigate();

    const onSubmit = () => {
        axios.post('/api/verify-user', {
            withCredentials: true,
            data: {
                verificationCode: code,
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                return navigate("/dashboard");
            }
        });
    }

    const renderSubmitButton = () => {
        if (code.length > 0) {
            return <button className='login-button approved-button' onClick={onSubmit}>Submit</button>
        }

        return <button className='login-button denied-button' onClick={onSubmit}>Submit</button>
    }

    return (
        <>
            <div className="login-container">
                <h1 className='header'>Verify Your Account</h1>
                <p>Before you can access everything with your new account, we need to do a quick verification.</p>
                <p>An email has been sent to your account with a code, retrieve that code and paste it below in order to verify.</p>
                <p>This process helps us protect our website from spammers and bots.</p>
                <p>Thank you.</p>
                <label for="verification">
                    Input Your Code:
                    <InputText id="verification" value={code} onChange={(event) => setCode(event.target.value)} />
                </label>
                {renderSubmitButton()}
            </div>
        </>
    )
}

export default VerificationForm;