import React, { useState } from "react";
import axios from "axios";

import Select from 'react-select';
import { InputTextarea } from 'primereact/inputtextarea';
import { Fade } from "react-reveal";

const reportOptions = [{ value: "Technical", label: "Technical" }, { value: "Financial", label: "Financial Assistance" },
{ value: "Staff", label: "Staff Relations" }, { value: "Other", label: "Other" }];

// Select Styling

const selectStyles = {
    option: (provided, state) => ({
        ...provided,
        fontWeight: state.isSelected ? "bold" : "normal",
        fontSize: "1.4rem",
        color: "#2d3436",
        backgroundColor: "#fff",
        "&:hover": {
            backgroundColor: "#f0f0f0", // Change to your desired hover background color
        },
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? "#2d3436" : "#fff",
        margin: "12px 0 12px"

    }),
}

function ReportForm(props) {
    const [type, setType] = useState(null);
    const [description, setDescription] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        axios.post('/api/report', {
            withCredentials: true,
            data: {
                type: type.value,
                description: description,
            }
        }).then((res) => {
            setSubmitted(res.status);
        })
    }

    const renderDescriptionLabel = () => {
        if (description.length <= 500) {
            return (
                <label>
                    Explain your Issue ({500 - description.length} Characters Remaining):
                </label>
            )
        }
        return (
            <label>
                Explain your Issue (Too Long!):
            </label>
        )
    }

    const renderSubmitButton = () => {
        if (type != null) {
            if (description.length > 0) {
                return (
                    <>
                        <div>
                            <button className="approved-button" onClick={handleSubmit}>Submit</button>
                        </div>
                    </>
                );

            }
        }
        return (

            <>
                <div>
                    <button className="denied-button" disabled>Submit</button>
                </div>
            </>
        );
    }

    if (submitted) {
        return (
            <div className="report-form">
                <h1 className='header'>Report Recieved!</h1>
                <p>We've recieved your report and our staff have been notified. Please wait patiently and we will solve your issue as soon as possible.</p>

            </div>
        )
    }

    return (
        <>
            <Fade delay={150}>
                <div className="report-form">

                    <h1 className='header'>We've got you covered.</h1>
                    <p>Pick the type of issue which most accurately describes the problem you're having and give us a description of the issue.</p>
                    <p>If you'd like to be updated on the status of your report, please include your email in the report.</p>
                    <p>We'll instantly get a notifcation of your report and solve the issue as soon as possible.</p>
                    <div className="report-form-item">
                        <div>
                            <label>
                                Type of Issue:
                            </label>
                        </div>
                        <div>
                            <Select
                                styles={selectStyles}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                value={type}
                                onChange={(option) => setType(option)} options={reportOptions} />
                        </div>

                    </div>

                    <div className="report-form-item">
                        <div>
                            {renderDescriptionLabel()}
                        </div>
                        <div>
                            <InputTextarea autoResize={true} rows={2} cols={15}
                                id="description" value={description}
                                onChange={(event) => setDescription(event.target.value)}
                            />
                        </div>

                    </div>
                    {renderSubmitButton()}
                </div>

            </Fade>

        </>
    )
}

export default ReportForm;