import React, { useState } from "react";
import { Helmet } from 'react-helmet';

// Custom Components
import RecoverForm from "../components/Recover/RecoverForm";

import './RecoverStyles.css';

function Recover() {

    return (
        <>
            <Helmet>
                <title>Recover Account | Hearts Unite</title>
                {/* Limits User Zoom */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <meta name="description" content="Recover your Hearts Unite to Give Support account." />
                <meta property="og:title" content="Recover Account | Hearts Unite" />
                <meta property="og:image" content="/images/hugs-logo.png" />
            </Helmet>
            <div className="recover">
                <RecoverForm />
            </div>
        </>
    )


}

export default Recover;