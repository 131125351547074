import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Custom Styles
import "./AttachmentStyles.css";

function AttachmentDownload(props) {

    const renderAttachmentContainer = () => {

        const openInNewTab = (url) => {
            window.open(url, '_blank', 'noreferrer');
        };

        return (
            <div className='attachment-container'>
                <div className='attachment-control-display'>
                    <h2 className='attachment-text header'>
                        {props.name} | {(props.size / 1024).toFixed(2)}kb
                    </h2>
                </div>

                <div className='attachment-control-button'>
                    <button onClick={() => openInNewTab(props.link)} className='remove-attachment-button approved-button'>View <i class="fa-solid fa-eye"></i></button>
                </div>

                <div className='attachment-control-button'>
                    <button className='remove-attachment-button approved-button'>  
                        <Link to={props.link} target="_blank" download>Download<i class="fa-solid fa-download"></i></Link>
                    </button>
                </div>
            </div>
        )
    }

    return (
        <>
            {renderAttachmentContainer()}
        </>

    )

}

export default AttachmentDownload;