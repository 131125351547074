import React, { useState, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Fade } from 'react-reveal';

import Testimonial from '../components/Sisterhood/Testimonial';

import "./SisterhoodStyles.css";

// Swiper for Images

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import 'swiper/modules/free-mode/free-mode.min.css';
import 'swiper/modules/navigation/navigation.min.css';
// import required modules
import { FreeMode, Navigation, Autoplay } from "swiper";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


function Sisterhood() {
    // Width / Height
    const [width, height] = useWindowSize();

    const renderDesktopInfo = () => {
        if (width > 680) {
            return (
                <Fade delay={750}>
                    <div className='info-container'>
                        <p>Sisterhood for women who bleed is here to provide education and resources from within and outside the bleeding disorder community.</p>
                        <p>We advocate alongside other women to bring awareness to our needs and struggle living within these disorders.
                            Our goal is to see every woman diagnosed and receiving proper treatment. We strive to bring awareness and inspire others to advocate for themselves.</p>
                    </div>
                </Fade>
            )
        }
    }

    const renderMobileInfo = () => {
        if (width <= 680) {
            return (
                <>
                    <div className='sisterhood-section'>
                        <h1 className='header mobile-info-header'>About Us</h1>
                        <div className='mobile-info-container'>
                            <p>Sisterhood for women who bleed is here to provide education and resources from within and outside the bleeding disorder community.</p>
                            <p>We advocate alongside other women to bring awareness to our needs and struggle living within these disorders.
                                Our goal is to see every woman diagnosed and receiving proper treatment. We strive to bring awareness and inspire others to advocate for themselves.</p>
                        </div>
                    </div>
                </>
            )
        }
    }

    const renderDescriptions = () => {
        if (width >= 1200) {
            return (
                <>
                    <div className='sisterhood-section'>
                        <div className='sisterhood-descriptions'>
                            <div className='left-description'>
                                <h1 className='header'>Stormy</h1>
                                <p>
                                    Stormy is a patient, parent of a son with Hemophilia, and an advocate for the community.
                                    With personal experience in Hemophilia and Ehler’s Danlos Syndrome, she became an advocate for women after experiencing a lack of treatment for herself.
                                </p>

                                <p>
                                    Left undiagnosed with Hemophilia B until her forties, she quickly learned that a diagnosis did not mean proper treatment if you were a woman.
                                </p>

                                <p>
                                    Stormy is a public speaker focusing on talks and programs for women and girls with bleeding disorders, fathers of said girls, and advocacy.
                                </p>

                                <p>
                                    She has also served on numerous advisory boards and participated in many projects and programs within the community. With her passion for people and willingness to share her story and experiences, she hopes to help create change for the women of our community.
                                </p>
                            </div>

                            <div className='description-middle'>
                                <h1 className='header'>Our Founders</h1>

                                <img className="founders-image" src="/images/stormy_and_brandee.png" alt="founders stormy and brandee" />
                            </div>

                            <div className='right-description'>
                                <h1 className='header'>Brandee</h1>
                                <p>
                                    Brandee, a dedicated advocate with over 25 years of experience in the von Willebrand community, was diagnosed with the condition. Her deep commitment to the cause was ignited when she heard stories of women struggling to obtain a diagnosis and access proper treatment.
                                </p>

                                <p>
                                    In response, she initiated the creation of Sisterhood for Women Who Bleed on Facebook and Instagram, offering a secure platform for women to come together, share their experiences, seek advice, and find the support they need.
                                </p>

                                <p>
                                    Brandee has actively shared her journey with von Willebrand disease (vWD) with a wide audience, including the CDC, Hope Factor Magazine, the podcast "Sister, You're Not Alone," and CME Outfitters.
                                </p>

                                <p>
                                    Additionally, she has contributed her expertise by serving on multiple advisory boards and participating in various community projects. Her unwavering hope is to witness meaningful changes that will benefit women facing similar challenges in the future.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        return (
            <div className='sisterhood-section'>
            <div className='sisterhood-descriptions'>
            <div className='description-middle'>
                    <h1 className='header'>Our Founders</h1>

                    <img className="founders-image" src="/images/stormy_and_brandee.png" alt="founders stormy and brandee" />
                </div>

                <div className='left-description'>
                    <h1 className='header'>Stormy</h1>
                    <p>
                        Stormy is a patient, parent of a son with Hemophilia, and an advocate for the community.
                        With personal experience in Hemophilia and Ehler’s Danlos Syndrome, she became an advocate for women after experiencing a lack of treatment for herself.
                    </p>

                    <p>
                        Left undiagnosed with Hemophilia B until her forties, she quickly learned that a diagnosis did not mean proper treatment if you were a woman.
                    </p>

                    <p>
                        Stormy is a public speaker focusing on talks and programs for women and girls with bleeding disorders, fathers of said girls, and advocacy.
                    </p>

                    <p>
                        She has also served on numerous advisory boards and participated in many projects and programs within the community. With her passion for people and willingness to share her story and experiences, she hopes to help create change for the women of our community.
                    </p>
                </div>

                <div className='right-description'>
                    <h1 className='header'>Brandee</h1>
                    <p>
                        Brandee, a dedicated advocate with over 25 years of experience in the von Willebrand community, was diagnosed with the condition. Her deep commitment to the cause was ignited when she heard stories of women struggling to obtain a diagnosis and access proper treatment.
                    </p>

                    <p>
                        In response, she initiated the creation of Sisterhood for Women Who Bleed on Facebook and Instagram, offering a secure platform for women to come together, share their experiences, seek advice, and find the support they need.
                    </p>

                    <p>
                        Brandee has actively shared her journey with von Willebrand disease (vWD) with a wide audience, including the CDC, Hope Factor Magazine, the podcast "Sister, You're Not Alone," and CME Outfitters.
                    </p>

                    <p>
                        Additionally, she has contributed her expertise by serving on multiple advisory boards and participating in various community projects. Her unwavering hope is to witness meaningful changes that will benefit women facing similar challenges in the future.
                    </p>
                </div>
            </div>
        </div>
        )
    }


    return (
        <>
            <div className='sisterhood'>
                <Helmet>
                    <title>Sisterhood | Hearts Unite</title>
                    <meta name="description" content="Sisterhood for women who bleed is here to provide education and resources from within and outside the bleeding disorder community. This is their page featuring their contributions on the Hearts Unite to Give Support website." />
                    <meta property="og:title" content="Sisterhood | Hearts Unite" />
                    <meta property="og:image" content="/images/hugs-logo.png" />
                </Helmet>

                <div className='sisterhood-header-container'>
                    <div className='sisterhood-header-intro-container'>

                        <div className='sisterhood-header'>

                            <div className='sisterhood-header-text-section'>
                                <Fade delay={150}>
                                    <h1>Sisterhood</h1>
                                </Fade>

                                <Fade delay={300}>
                                    <h2>For Bleeding Disorders</h2>
                                </Fade>
                            </div>
                        </div>

                        {renderDesktopInfo()}
                    </div>

                </div>

                {renderMobileInfo()}

                {renderDescriptions()}

                <div className='sisterhood-section'>
                    <div className='dotbox-section'>
                        <Swiper
                            style={{
                                "--swiper-navigation-color": "#fff",
                                "--swiper-pagination-color": "#fff",
                            }}
                            spaceBetween={10}
                            slidesPerView={1}
                            navigation={true}
                            loop={true}
                            autoHeight={true}
                            modules={[FreeMode, Navigation, Autoplay]}
                            className="swiper-main"
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true,
                                stopOnLastSlide: false,
                            }}
                            breakpoints={{
                                1300: {
                                    slidesPerView: 1,
                                },
                            }}
                        >

                            <SwiperSlide>
                                <div className='sisterhood-testimonial'>
                                    <h1 className='header'>Dot Box</h1>
                                    <Testimonial src="/images/sisterhood-dotbox-1.jpg"
                                        alt={'picture of dot box'} text={'Thank you so much for my dot box! It came on a perfect day. The cozy little extras are so perfect!'} />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='sisterhood-testimonial'>
                                    <h1 className='header'>Dot Box</h1>
                                    <Testimonial src="/images/sisterhood-dotbox-2.jpg"
                                        alt={'picture of girl with dot box'} text={'It is so easy to feel isolated as a woman in the bleeding community, but this little box was a great reminder that we are not alone.'} />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='sisterhood-testimonial'>
                                    <h1 className='header'>Dot Box</h1>
                                    <Testimonial src="/images/sisterhood-dotbox-3.jpg"
                                        alt={'picture of woman with dot box'} text={'This was the best day! I love my dot box full of everything I need and tons of extras. I never knew it came with a beautiful card and bleeding info. I literally started crying. I feel so seen. Thank you.'} />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='sisterhood-testimonial'>
                                    <h1 className='header'>Dot No More Box</h1>
                                    <Testimonial src="/images/sisterhood-dotnomorebox-1.jpg"
                                        alt={'picture of dot no more box'} text={'Love my box! Stormy went above and beyond on it.'} />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='sisterhood-testimonial'>
                                    <h1 className='header'>Dot No More Box</h1>
                                    <Testimonial src="/images/sisterhood-dotnomorebox-2.jpg"
                                        alt={'picture of dot no more box 2'} text={"I love my box! Ladies, you won't be disappointed."} />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='sisterhood-testimonial'>
                                    <h1 className='header'>Dot No More Box</h1>
                                    <Testimonial src="/images/sisterhood-dotnomorebox-3.jpg"
                                        alt={'picture of dot no more box 3'} text={'I enjoyed my box and will continue to enjoy the items in it!'} />
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </div>
                </div>

                {/* Commenting this out until I get a proper contact page for them */}
                {/* <div className='learn-more'>
                    <h2>Want to Learn More About Sisterhood?</h2>
                    <button>Contact Us</button>
                </div> */}
            </div>
        </>

    )
}

export default Sisterhood;