import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

// Custom Components
import EmployeeDashboard from '../components/Dashboard/Employee/EmployeeDashboard';
import UserDashboard from '../components/Dashboard/User/UserDashboard';

// Custom Styles
import "./DashboardStyles.css";

export default function Dashboard(props) {
    const userData = props.userData;

    if (!userData || userData === null) {
        // useNavigate simply does not want to work for this, window.location.href does however work so it is here.
        window.location.href = '/login';
    }

    // Verifies employee status before handing over EmployeeDashboard
    const renderDashboard = () => {
        if (userData.employee) {
            // Perform API check for employee here
            return (
                <EmployeeDashboard userData={userData} />
            );

        } else {
            return (
                <UserDashboard userData={userData} />
            );
        }
    }

    return (
        <>
            <Helmet>
                <title>Dashboard | Hearts Unite</title>
                {/* Limits User Zoom */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <meta name="description" content="Your Hearts Unite to Give Support dashboard for tracking and reviewing applications for financial assistance." />
                <meta property="og:title" content="Dashboard | Hearts Unite" />
                <meta property="og:image" content="/images/hugs-logo.png" />
            </Helmet>
            <div className="dashboard">
                {renderDashboard()}
            </div>

        </>

    )

}