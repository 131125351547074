import React, { Component } from "react";

import Navbar from "./Navbar";
import Footer from "./Footer";

class LoadingScreen extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Navbar userData={null} loading={true} />
            </>
        )
    }
}

export default LoadingScreen;