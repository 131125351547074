import React, { useState } from "react";
import axios from 'axios';

function useFetchData() {
    axios.defaults.withCredentials = true;
    const [loading, setLoading] = useState(true);
    const [eventsData, setEventsData] = useState(null);

    React.useEffect(() => {
        if (!eventsData) {
            setLoading(true);
            axios.get('/api/upcoming-events', { withCredentials: true })
                .then((res) => {
                    if (res.data.status) {
                        setEventsData(res.data.events);
                    } else {
                        setEventsData(null);
                    }

                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                })
        }

    }, []);
    return { loading, eventsData };
}

export default function UserEventsDisplay(props) {
    // Width
    const width = props.width;

    const { loading, eventsData } = useFetchData();

    if (eventsData) {
        return (
            <>
                <div className="user-dashboard-events">
                    <div>
                        <h1 className="header">{width > 780 ? "We Have Events Coming Up" : "Upcoming Events"}</h1>
                        {eventsData.map((event, index) => {
                            return (
                                <h2 className="header">{event.name} - {event.date}</h2>
                            )
                        })}
                    </div>

                    <div>
                        <h2 className="header">Interested In Attending?</h2>
                        <p>If you'd like to attend, please click the button below to be redirected to sign up!</p>
                        <form action="/events">
                            <button action="submit" className="approved-button">Click Here to Find Out More</button>
                        </form>
                    </div>

                </div>
            </>
        )
    }
}