import React from "react";
import { FooterData } from "./FooterData";
import "./FooterStyles.css";

function Footer() {
    return (
        <>
            <div className="footer">
                <h1 className="footer-header-text header">Hearts Unite to Give Support</h1>
                <ul className="footer-lists">
                    {FooterData.map((item, index) => {
                        return (
                            <li key={index}>
                                <h3 className="list-label header">{item.title}</h3>
                                <ul className="internal-footer-list">
                                    {item.links.map((internalItem, internalIndex) => {
                                        return (
                                            <li key={index}>
                                                <a href={internalItem.url} className={internalItem.cName}>
                                                    <i className={internalItem.icon}></i> {internalItem.title}
                                                </a>
                                            </li>
                                        )
                                    })
                                    }
                                </ul>
                            </li>
                        )
                    })
                    }
                </ul>
                <p className="bottom-text">Website Made With Love <i className="fa-regular fa-heart" i /></p>
                <p className="bottom-text">Owned and Operated by <a href='https://stoneculver.com'>Stone Culver</a></p>
                <p className="bottom-text"> <i className="fa-regular fa-copyright" i /> 2024 Hearts Unite to Give Support</p>
            </div>
        </>
    )

}

export default Footer;