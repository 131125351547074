import React, { useState, useLayoutEffect, useEffect } from 'react';
import axios from 'axios';

import DashboardWelcome from '../User/DashboardWelcome';
import EmployeeNavbar from './EmployeeNavbar';
import EmployeeApplicationControl from './EmployeeApplicationControl';
import EmployeeEventControl from './EmployeeEventControl';
import EmployeeReportControl from './EmployeeReportControl';
import EmployeeUserControl from './EmployeeUserControl';
import EmployeeStats from './EmployeeStats';

import Fade from 'react-reveal/Fade';

import "./EmployeeDashboardStyles.css";

const showNothing = 0;
const showStats = 1;
const showApplications = 2;
const showReports = 3;
const showEvents = 4;
const showUsers = 5;

function EmployeeDashboard(props) {
    const [display, setDisplay] = useState(showStats);
    const [legacyApplications, setLegacyApplications] = useState(null);
    const [legacyApplicationsLoading, setLegacyApplicationsLoading] = useState(true);
    const [applications, setApplications] = useState(null);
    const [pendingApplications, setPendingApplications] = useState(null);
    const [applicationsLoading, setApplicationsLoading] = useState(true);
    const [events, setEvents] = useState(null);
    const [upcomingEvents, setUpcomingEvents] = useState(null);
    const [eventsLoading, setEventsLoading] = useState(false);
    const [reports, setReports] = useState(null);
    const [pendingReports, setPendingReports] = useState(null);
    const [reportsLoading, setReportsLoading] = useState(false);
    const [users, setUsers] = useState(null);
    const [usersLoading, setUsersLoading] = useState(null);

    useEffect(() => {
        axios.defaults.withCredentials = true;

        // Applications
        if (!applications) {
            setApplicationsLoading(true);
            axios
                .get('/api/applications', { withCredentials: true })
                .then((res) => {
                    // Removed sort by date function, .reverse() works way easier
                    setApplications(res.data.applications.reverse());
                    // Parse applications and get total pending
                    var localPending = 0;
                    res.data.applications.forEach(application => {
                        if (application.status === "Pending") {
                            localPending++;
                        }
                    });

                    setPendingApplications(localPending);
                    setApplicationsLoading(false);
                })
                .catch((err) => {
                    setApplicationsLoading(false);
                });
        }

        // Legacy Applications
        if (!legacyApplications) {
            setLegacyApplicationsLoading(true);
            axios
                .get('/api/legacy-applications', { withCredentials: true })
                .then((res) => {
                    setLegacyApplications(res.data.applications.reverse());
                    console.log(res.data.applications);

                    setLegacyApplicationsLoading(false);
                })
                .catch((err) => {
                    setApplicationsLoading(false);
                });
        }

        // Events
        if (!events) {
            setEventsLoading(true);
            axios.get('/api/events', { withCredentials: true })
                .then((res) => {
                    setEvents(res.data.events);
                    // Parse events and get total upcoming
                    var localUpcoming = 0;
                    res.data.events.forEach(event => {
                        if (event.active) {
                            localUpcoming++;
                        }
                    })
                    setUpcomingEvents(localUpcoming);
                    setEventsLoading(false);
                })
                .catch((err) => {
                    setEventsLoading(false);
                })
        }

        // Reports

        if (!reports) {
            setReportsLoading(true);
            axios.get('/api/reports', { withCredentials: true })
                .then((res) => {
                    setReports(res.data.reports.reverse());
                    // Parse reports and get total unresolved
                    var localPending = 0;
                    res.data.reports.forEach(report => {
                        if (!report.resolved) {
                            localPending++;
                        }
                    })
                    setPendingReports(localPending);
                    setReportsLoading(false);
                })
                .catch((err) => {
                    setReportsLoading(false);
                })
        }

        // Users

        if (!users) {
            setUsersLoading(true);
            axios.get('/api/users', { withCredentials: true })
                .then((res) => {
                    setUsers(res.data.users);
                    setUsersLoading(false);
                })
                .catch((err) => {
                    setUsersLoading(false);
                })
        }

    }, [applications, events, reports, users])

    const updateDisplay = (value) => {
        setDisplay(value);
    }

    const renderDisplayContainer = () => {
        switch (display) {
            case showNothing:
                return (
                    <div className='display-container'>
                        <h1 className='header'>Nothing Here Yet!</h1>
                        <p>Press a button above to get started.</p>
                    </div>
                )

            case showStats: {
                if (!applicationsLoading && !reportsLoading && !eventsLoading && !usersLoading && !legacyApplicationsLoading) {
                    return <EmployeeStats users={users} applications={applications}
                        events={events} reports={reports} legacyApplications={legacyApplications}/>
                } else {
                    return (
                        <>
                            <div className='display-container'>
                                <h1 h1 className='header'>Statistics</h1>
                                <p>Loading...</p>
                            </div>
                        </>
                    )
                }
            }

            case showApplications: {
                if (!applicationsLoading && !legacyApplicationsLoading) {
                    return (
                        <EmployeeApplicationControl applications={applications} setApplications={setApplications} 
                            legacyApplications={legacyApplications} setLegacyApplications={setLegacyApplications}
                        />
                    )
                } else {
                    return (
                        <>
                            <div className='display-container'>
                                <h1 h1 className='header'>Applications</h1>
                                <p>Loading...</p>
                            </div>
                        </>
                    )
                }
            }

            case showReports: {
                if (!reportsLoading) {
                    return (
                        <EmployeeReportControl reports={reports} setReports={setReports} />
                    )
                } else {
                    return (
                        <>
                            <div className='display-container'>
                                <h1 h1 className='header'>Reports</h1>
                                <p>Loading...</p>
                            </div>
                        </>
                    )
                }
            }

            case showEvents: {
                if (!eventsLoading) {
                    return (
                        <EmployeeEventControl events={events} setEvents={setEvents} />
                    )
                } else {
                    return (
                        <>
                            <div className='display-container'>
                                <h1 h1 className='header'>Events</h1>
                                <p>Loading...</p>
                            </div>
                        </>
                    )
                }
            }

            case showUsers: {
                if (!usersLoading) {
                    return (
                        <EmployeeUserControl users={users} setUsers={setUsers} />
                    )
                } else {
                    return (
                        <>
                            <div className='display-container'>
                                <h1 h1 className='header'>Users</h1>
                                <p>Loading...</p>
                            </div>
                        </>
                    )
                }
            }

            default:
                return (
                    <div className='display-container'>
                        <h1>Nothing Here Yet</h1>
                        <p>Press a button above to get started.</p>
                    </div>
                )
        }
    }

    return (
        <>
            <Fade>
                <DashboardWelcome fName={props.userData.firstName} />
                <EmployeeNavbar applications={applicationsLoading ? 0 : pendingApplications}
                    reports={reportsLoading ? 0 : pendingReports}
                    events={eventsLoading ? 0 : upcomingEvents}
                    updateDisplay={updateDisplay}
                    showApplications={showApplications}
                    showReports={showReports}
                    showEvents={showEvents}
                    showUsers={showUsers}
                    showStats={showStats}
                />
            </Fade>

            {renderDisplayContainer()}

        </>
    )
}

export default EmployeeDashboard;