import React, { useState, useLayoutEffect } from "react";

import { Fade } from "react-reveal";

// Custom Components
import NewsletterSignupForm from "../General/NewsletterSignupForm";

// Custom Styles
import "./HomeNewsletterStyles.css"

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function HomeNewsletter() {
    const [width, height] = useWindowSize();

    const renderComponent = () => {
        if (width > 680) {
            return (
                <>
                    <div className="newsletter-container">
                        <h1 className="header">Frequent Updates</h1>

                        <div className="newsletter-items">

                            <div className="newsletter-item">
                                <img src="/images/HUGS_news.png" alt="person showing hugs newsletter" className="newsletter-image" />

                            </div>

                            <div className="newsletter-item">
                                <Fade delay={150}>
                                    <NewsletterSignupForm />
                                </Fade>

                            </div>
                        </div>
                    </div>
                </>
            )
        }

        return (
            <>
            <div className="newsletter-container">
                <h1 className="header">Frequent Updates</h1>

                <div className="newsletter-items">

                    <div className="newsletter-item">
                        <img src="/images/HUGS_news.png" alt="person showing hugs newsletter" className="newsletter-image" />

                    </div>

                    <div className="newsletter-item">
                        <Fade delay={150}>
                            <NewsletterSignupForm />
                        </Fade>

                    </div>
                </div>
            </div>
        </>
        )
    }

    return (
        <>
            {renderComponent()}
        </>
    )
}