import React, { Component } from "react";
import Fade from 'react-reveal/Fade';
import axios from "axios";

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

axios.defaults.withCredentials = true;

const forgot = 2;

class SignupBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: props.signupEmail, password: props.signupPassword, passwordConfirm: props.signupPasswordConfirm,
            firstName: props.fName,
            lastName: props.lName, invalidPress: false, errorMsg: "", signupComplete: props.signupComplete,
        }

    }

    verifyPassword = (password) => {
        const symbols = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '+', '=', '[', ']', "'", '"', ';', ':', ',', '.', '/', '<', '>', '?', '`', '~'];
        const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        const caps = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
            'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        var validSymbols = false;
        var validNumbers = false;
        var validCaps = false;

        for (var i = 0; i < symbols.length; i++) {
            if (password.includes(symbols[i])) {
                validSymbols = true;
                break;
            }
        }

        if (validSymbols) {
            for (var i = 0; i < numbers.length; i++) {
                if (password.includes(numbers[i])) {
                    validNumbers = true;
                    break;
                }
            }
        } else {
            this.setState({ invalidPress: true, errorMsg: "Password Must Contain 1 Symbol, Uppercase Letter, and Number!" });
            return false;
        }

        if (validNumbers) {
            for (var i = 0; i < caps.length; i++) {
                if (password.includes(caps[i])) {
                    validCaps = true;
                    break;
                }
            }
        } else {
            this.setState({ invalidPress: true, errorMsg: "Password Must Contain 1 Symbol, Uppercase Letter, and Number!" });
            return false;
        }

        if (validCaps) {
            return true;
        } else {
            this.setState({ invalidPress: true, errorMsg: "Password Must Contain 1 Symbol, Uppercase Letter, and Number!" });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.verifyPassword(this.state.password)) {
            axios.post("/api/user", {
                withCredentials: true,
                data: {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email,
                    password: this.state.password,
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((res) => {
                if (res.data.status) {
                    window.location.reload(false);
                } else {
                    this.setState({ invalidPress: true, errorMsg: res.data.message });
                }
            })
        }
    }

    verifyEmail = () => {
        if (this.state.email.includes('@') && this.state.email.includes('.')) {
            return true;
        }
        return false;
    }

    onEmailInput = (event) => {
        this.setState({ email: event.target.value, invalidPress: false });
        this.props.handleSignupEmail(this.state.email);
    }

    onPasswordInput = (event) => {
        this.setState({ password: event.target.value, invalidPress: false });
        this.props.handleSignupPassword(this.state.password);
    }

    onPasswordConfirmInput = (event) => {
        this.setState({ passwordConfirm: event.target.value, invalidPress: false });
    }

    onFirstNameInput = (event) => {
        this.setState({ firstName: event.target.value, invalidPress: false });
        this.props.handleFirstName(this.state.firstName);
    }

    onLastNameInput = (event) => {
        this.setState({ lastName: event.target.value, invalidPress: false });
        this.props.handleLastName(this.state.lastName);
    }

    onPasswordConfirmInput = (event) => {
        this.setState({ passwordConfirm: event.target.value, invalidPress: false });
        this.props.handleSignupPasswordConfirm(this.state.passwordConfirm);
    }

    renderSignupButton() {
        if (this.state.firstName.length > 0 && this.state.firstName.length < 50 &&
            this.state.lastName.length > 0 && this.state.lastName.length < 50 &&
            this.state.password.length > 0 && this.state.password.length < 50
            && this.verifyEmail()
            && this.state.password === this.state.passwordConfirm) {
            return <button className="login-button approved-button" type="submit">Create Account <i class="fa-solid fa-check" /></button>;
        } else {
            return <button className="login-button denied-button" type="button" onClick={this.toggleInvalidPress}>Create Account <i class="fa-thin fa-x" /></button>;
        }
    }

    toggleInvalidPress = () => {
        this.setState({ invalidPress: true });
        if (this.state.firstName.length === 0 || this.state.firstName.length > 50) {
            this.setState({ errorMsg: "Invalid First Name Length!" });
        }

        if (this.state.lastName.length === 0 || this.state.lastName.length > 50) {
            this.setState({ errorMsg: "Invalid Last Name Length!" });
        }

        if (this.state.email.length === 0 || this.state.email.length > 50) {
            this.setState({ errorMsg: "Invalid Email Length or Format!" });
        }

        if (this.state.password.length === 0 || this.state.password.length > 50) {
            this.setState({ errorMsg: "Invalid Password Length!" });
        }

        if (this.state.password != this.state.passwordConfirm) {
            this.setState({ errorMsg: "Passwords Must Match!" });
        }
    }

    renderSignupError() {
        if (this.state.invalidPress) {
            return (<>

                <h3 className="error-text">{this.state.errorMsg}</h3>

            </>)
        } else {
            return "";
        }
    }

    render() {
        return (
            <>
                <h1 className="header">Signup for Account</h1>
                <div className="login-box">

                    <form onSubmit={this.handleSubmit}>

                        <label>
                            First Name: {this.state.firstName.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText value={this.state.firstName} onChange={this.onFirstNameInput} />
                        </label>

                        <label>
                            Last Name: {this.state.lastName.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText value={this.state.lastName} onChange={this.onLastNameInput} />
                        </label>


                        <label>
                            Email: {this.verifyEmail() ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                            <InputText value={this.state.email} onChange={this.onEmailInput} />
                        </label>

                        <label>
                            Password: {this.state.password.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <Password type="password" name="password" value={this.state.password} onChange={this.onPasswordInput} toggleMask={true} />
                        </label>

                        <label>
                            Confirm Password: {this.state.password.length > 0 && this.state.password === this.state.passwordConfirm ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                            <Password value={this.state.passwordConfirm} onChange={this.onPasswordConfirmInput}/>
                        </label>

                        <Fade bottom when={this.state.invalidPress}>
                            {this.renderSignupError()}
                        </Fade>

                        <div className="login-buttons">
                            <div>
                                {this.renderSignupButton()}
                            </div>

                            <div>
                                <button className="login-button pending-button" type="button" onClick={() => this.props.setShowing(forgot)}>Forgot Password? <i class="fa-solid fa-thought-bubble"></i></button>
                            </div>
                        </div>

                    </form>
                </div>
            </>
        )
    }
}

export default SignupBox;