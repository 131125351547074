import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from 'axios';

import Select from 'react-select';

import AttachmentContainer from "../../General/AttachmentContainer";
import EventPicturesChange from "./EventPicturesChange";

// Custom Components
import { StateOptions } from "../../Apply/StateOptions";

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";
import { Password } from "primereact/password";
import AttendingSearch from "./AttendingSearch";
import EventArchive from "./EventArchive";

const nothing = 0;
const editText = 1;
const addPictures = 2;
const all = 3;
const archiveEvent = 4;
const verifyEditsShowing = 5;

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function EmployeeEventEdit(props) {

    // Handle updating state value to object state
    useEffect(() => {
        const stateObject = StateOptions.find(item => item.value === props.event.state);
        setNewState(stateObject);
    }, []);

    // Width / Height
    const [width, height] = useWindowSize();

    // States
    const [name, setName] = useState(props.event.name);
    const [newName, setNewName] = useState(props.event.name);
    const [date, setDate] = useState(props.event.date);
    const [newDate, setNewDate] = useState(props.event.date);
    const [dateAdded, setDateAdded] = useState(props.event.dateAdded);
    const [online, setOnline] = useState(props.event.online);
    const [newOnline, setNewOnline] = useState(props.event.online);
    const [address, setAddress] = useState(props.event.streetAddress);
    const [newAddress, setNewAddress] = useState(props.event.streetAddress);
    const [address2, setAddress2] = useState(props.event.streetAddress2);
    const [newAddress2, setNewAddress2] = useState(props.event.streetAddress2);
    const [city, setCity] = useState(props.event.city);
    const [newCity, setNewCity] = useState(props.event.city);
    const [state, setState] = useState(props.event.state);
    const [newState, setNewState] = useState(undefined);
    const [zip, setZip] = useState(props.event.zip);
    const [newZip, setNewZip] = useState(props.event.zip);
    const [description, setDescription] = useState(props.event.description);
    const [newDescription, setNewDescription] = useState(props.event.description);
    const [attending, setAttending] = useState(props.event.attending);
    const [attendingInfo, setAttendingInfo] = useState(props.event.attendingInfo);
    const [time, setTime] = useState(props.event.time);
    const [newTime, setNewTime] = useState(props.event.time);
    const [images, setImages] = useState(props.event.images);
    const [pendingImage, setPendingImage] = useState(null);
    const [pendingImages, setPendingImages] = useState([]);
    const [active, setActive] = useState(props.event.active);
    const [newActive, setNewActive] = useState(props.event.active);
    const [editShowing, setEditShowing] = useState(all);
    const [instructions, setInstructions] = useState(props.event.instructions);
    const [newInstructions, setNewInstructions] = useState(props.event.instructions);
    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [key, setKey] = useState("");
    // Select Styling

    const selectStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? "bold" : "normal",
            fontSize: width <= 1260 ? "1.2rem" : "1.4rem",
            color: "#2d3436",
            backgroundColor: "#fff",
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#2d3436' : '#fff',
            "&:hover": {
                borderColor: '#2d3436',
                color: '#2d3436',
            },
            height: width <= 1260 ? "48px" : "55px",
            margin: "12px 0 12px",

        }),
    }

    const onUpdateEvent = (event) => {
        event.preventDefault();
        axios.defaults.withCredentials = true;

        const formData = new FormData();
        formData.append('id', props.event._id);
        formData.append('name', newName);
        formData.append('date', newDate);
        formData.append('description', newDescription);
        formData.append('online', newOnline);
        formData.append('streetAddress', newAddress);
        formData.append('streetAddress2', newAddress2);
        formData.append('city', newCity);
        formData.append('state', newState.value);
        formData.append('zip', newZip);
        formData.append('active', newActive);
        formData.append('time', newTime);
        formData.append('instructions', newInstructions);
        formData.append('key', key);
        axios.post('/api/update-event', formData, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                setName(newName);
                setDate(newDate);
                setDescription(newDescription);
                setOnline(newOnline);
                setAddress(newAddress);
                setAddress2(newAddress2);
                setCity(newCity);
                setState(newState.value);
                setZip(newZip);
                setActive(newActive);
                setInstructions(newInstructions);
                setUpdated(true);
            } else {
                setError(true);
                setErrorMsg(res.data.message);
            }

        })
    }

    const onImageAttach = () => {
        if (pendingImage != null) {
            setPendingImages(pendingImages.concat(pendingImage));
            setPendingImage(null);
        }
    }

    const onImageUpload = (event) => {
        setPendingImage(event.target.files[0]);
        event.target.value = null;
    }

    // Images

    const renderImages = () => {
        if (pendingImages.length > 0) {
            return (
                <>
                    <ul className="attachments-list">
                        {pendingImages.map((image, index) => {
                            return (
                                <li key={index}>
                                    <AttachmentContainer name={image.name} type={image.type} size={image.size}
                                        pending={false}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                </>
            )
        } else {
            return (
                <>
                    <h3>Nothing Yet!</h3>
                </>
            )
        }
    }

    const renderPendingImage = () => {
        if (pendingImage) {
            return (
                <>
                    <div>
                        <AttachmentContainer name={pendingImage.name} type={pendingImage.type} size={pendingImage.size}
                            pending={true}
                        />
                    </div>
                </>
            )
        } else {
            return <h3>Nothing Here!</h3>
        }
    }

    const renderUploadImagesButton = () => {
        if (pendingImages.length != 0) {
            return (
                <div className="event-buttons-container">
                    <button className="approved-button" onClick={() => {
                        setNewName(name);
                        setNewDate(date);
                        setNewDescription(description);
                        setEditShowing(nothing)
                    }}>Go Back</button>
                    <button className="approved-button" onClick={onUpdateEvent}>Upload Images</button>
                </div>

            )
        }

        return (
            <div className="event-buttons-container">
                <button className="approved-button" onClick={() => {
                    setNewName(name);
                    setNewDate(date);
                    setNewDescription(description);
                    setEditShowing(nothing)
                }}>Go Back</button>
                <button className="declined-button">Upload Images</button>
            </div>

        )
    }

    // Event Edit

    const renderSaveChanges = () => {
        if (name != newName || date != newDate || description != newDescription || active != newActive || online != newOnline || address != newAddress
            || address2 != newAddress2 || city != newCity || state != newState.value || zip != newZip || time != newTime
            || instructions != newInstructions) {
            return (
                <button className="approved-button resolve-button" onClick={() => setEditShowing(verifyEditsShowing)}>Verify & Submit</button>
            )
        }

        return (
            <button className="denied-button resolve-button">Verify & Submit</button>
        )
    }

    const renderAddressForm = () => {
        if (!newOnline) {
            return (
                <>
                    <div className="event-row">
                        <label for="address1">
                            Street Address:

                            <InputText id='name' value={newAddress} onChange={(event) => setNewAddress(event.target.value)} />
                        </label>

                        <label for="address2">
                            Street Address 2:

                            <InputText value={newAddress2} onChange={(event) => setNewAddress2(event.target.value)} />
                        </label>

                        <label for="city">
                            City:

                            <InputText value={newCity} onChange={(event) => setNewCity(event.target.value)} />
                        </label>

                    </div>

                    <div className="two-item-section">

                        <div className="section-item">
                            <label>
                                State:
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={newState}
                                    onChange={(option) => setNewState(option)} options={StateOptions} />
                            </label>
                        </div>

                        <div className="section-item">
                            <label>
                                ZIP:

                                <InputText value={newZip} onChange={(event) => setNewZip(event.target.value)} />
                            </label>
                        </div>

                    </div>
                </>
            )
        }
    }

    const renderEventLocation = () => {
        if (online) {
            return <h2>Online</h2>
        }

        return (
            <>
                <div>
                    <h3>{address}</h3>
                    <h3>{address2}</h3>
                    <h3>{city}</h3>
                    <h3>{state}, {zip}</h3>
                    <h3>{time}</h3>
                </div>
            </>
        )
    }

    const renderNewEventLocation = () => {
        if (newOnline) {
            return <h2>Online</h2>
        }

        return (
            <>
                <div>
                    <h3>{newAddress}</h3>
                    <h3>{newAddress2}</h3>
                    <h3>{newCity}</h3>
                    <h3>{newState.value}, {newZip}</h3>
                    <h3>{newTime}</h3>
                </div>
            </>
        )
    }

    const renderVerifyOrUpdated = () => {
        if (!updated) {
            return (
                <>
                    <div className="application-container">
                        <div className='back-button'>
                            <button className='denied-button' onClick={() => setEditShowing(editText)}><i class="fa-solid fa-left-long"></i> Go Back</button>
                        </div>
                        <h1 className="header">Confirm Changes</h1>
                        <div className="event-row">
                            <div>
                                <h2>Event Name: </h2>
                                <p>{name}</p>
                            </div>

                            <div>
                                <h2>Event Date: </h2> <p>{date}</p>
                            </div>

                            <div>
                                <h2>Event Time: </h2> <p>{date}</p>
                            </div>
                        </div>

                        <div className='event-row'>
                            <div>
                                <h2>Event Added on: </h2> <p>{dateAdded}</p>
                            </div>

                            <div>
                                <h2>Event Images: </h2> <p>{images.length}</p>
                            </div>

                            <div>
                                <h2>Attending: </h2> <p>{attending}</p>
                            </div>

                        </div>

                        <div>
                            <h2>Event Description</h2>
                            <p>{description}</p>
                        </div>

                        <div>
                            <h2>Event Location</h2>
                            {renderNewEventLocation()}
                        </div>

                        <div className='response-password-input-container'>
                            <label>
                                Input Employee Key to Verify: {key.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <Password type="password" name="key" value={key} onChange={(event) => setKey(event.target.value)} toggleMask={true} />
                            </label>
                        </div>
                        <button className="approved-button resolve-button" onClick={onUpdateEvent}>Verify & Submit</button>
                    </div>
                </>

            )
        }

        return (
            <>
                <h1 className="header">Event Updated!</h1>
                <p>The event has been updated and changes will reflect on the website immediately.</p>
            </>

        )
    }

    const renderEventEdit = () => {
        switch (editShowing) {
            case (all):
                return (
                    <>
                        <div className="application-container">
                            <div className="event-edit">
                                <h1 className="header">All Information</h1>
                                <div className="event-row">
                                    <div>
                                        <h2>Event Name: </h2>
                                        <p>{name}</p>
                                    </div>

                                    <div>
                                        <h2>Event Date: </h2> <p>{date}</p>
                                    </div>

                                    <div>
                                        <h2>Event Time: </h2> <p>{date}</p>
                                    </div>
                                </div>

                                <div className='event-row'>
                                    <div>
                                        <h2>Event Added on: </h2> <p>{dateAdded}</p>
                                    </div>

                                    <div>
                                        <h2>Event Images: </h2> <p>{images.length}</p>
                                    </div>

                                    <div>
                                        <h2>Attending: </h2> <p>{attending}</p>
                                    </div>

                                </div>

                                <div>
                                    <h2>Event Description</h2>
                                    <p>{description}</p>
                                </div>

                                <div>
                                    <h2>Event Location</h2>
                                    {renderEventLocation()}
                                </div>

                                <div className="attending-display">
                                    <h1 className="header">Registered as Attending</h1>
                                    <AttendingSearch attending={attendingInfo} />
                                    <div>
                                        <h1 className="header">All Attendees</h1>
                                        {attendingInfo.map((person, index) => {
                                            return (
                                                <>
                                                    <div className="search-item">
                                                        <h2>{person.firstName} {person.lastName} | {person.email}</h2>
                                                        <p>{person.info}</p>
                                                    </div>
                                                </>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                )

            case (editText):
                return (
                    <>
                        <div className="application-container">
                            <div className="event-edit">
                                <h1 className="header">Edit Event</h1>
                                <div className='event-row'>
                                    <div>
                                        <h2>Event Name: </h2>
                                        <InputText id='name' value={newName} onChange={(event) => setNewName(event.target.value)} />
                                    </div>

                                    <div>
                                        <h2>Event Date: </h2>
                                        <InputText id='date' value={newDate} onChange={(event) => setNewDate(event.target.value)} />
                                    </div>

                                </div>

                                <div className="event-row">
                                    <div className='application-info-item'>
                                        <h2>Event Added on: </h2>
                                        <p>{dateAdded}</p>
                                    </div>

                                    <div className='application-info-item'>
                                        <h2>Event Images: </h2>
                                        <p>{images.length}</p>
                                    </div>

                                    <div>
                                        <h2>Attending: </h2> <p>{attending}</p>
                                    </div>
                                </div>


                                <div>
                                    <h2>Event Description</h2>
                                    <InputTextarea autoResize={true} rows={5} cols={30}
                                        id="description" value={newDescription} onChange={(event) => { setNewDescription(event.target.value) }} />
                                </div>

                                <div>
                                    <label>
                                        <h2>Event Active?</h2>
                                    </label>

                                    <div className="event-buttons-container">
                                        <button className={newActive ? "" : "declined"} onClick={() => setNewActive(false)}>No</button>
                                        <button className={newActive ? "approved" : ""} onClick={() => setNewActive(true)}>Yes</button>
                                    </div>
                                </div>

                                <h1 className="header">Event Address</h1>

                                <div className="active-section">
                                    <label>
                                        <h2>Event Online?</h2>
                                    </label>

                                    <div className="event-buttons-container">
                                        <button className={newOnline ? "" : "declined"} onClick={() => setNewOnline(false)}>No</button>
                                        <button className={newOnline ? "approved" : ""} onClick={() => setNewOnline(true)}>Yes</button>
                                    </div>
                                </div>

                                {renderAddressForm()}

                                <div className="event-time">
                                    <label>
                                        Time:

                                        <InputText value={newTime} onChange={(event) => setNewTime(event.target.value)} />
                                    </label>
                                </div>

                                <div className="event-instructions">
                                    <h1 className="header">RSVP Instructions</h1>
                                    <p>Please limit instructions to 1 sentence, for example: </p>
                                    <p>"Please list children attending and any dietary restrictions."</p>
                                    <InputText autoResize={true} rows={5} cols={30}
                                        id="description" value={newInstructions} onChange={(event) => setNewInstructions(event.target.value)} />
                                </div>

                                {renderSaveChanges()}
                            </div>
                        </div>

                    </>
                )

            case (verifyEditsShowing):
                return (
                    <>
                        {renderVerifyOrUpdated()}
                    </>
                )

            case (addPictures):
                return <EventPicturesChange event={props.event} />

            case (archiveEvent):
                return <EventArchive event={props.event} />
        }
    }

    const renderEventEditControl = () => {
        return (
            <>
                <div className="event-control-items">
                    <div>
                        <button className="search-button" onClick={() => {
                            if (editShowing != all) {
                                setEditShowing(all);
                            } else {
                                setEditShowing(nothing);
                            }

                        }}>View All Info</button>
                    </div>

                    <div>
                        <button className="pending-button" onClick={() => {
                            if (editShowing != editText) {
                                setEditShowing(editText);
                            } else {
                                setEditShowing(nothing);
                            }
                        }}>Edit Details</button>
                    </div>

                    <div>
                        <button className="approved-button" onClick={() => {
                            if (editShowing != addPictures) {
                                setEditShowing(addPictures);
                            } else {
                                setEditShowing(nothing);
                            }
                        }}>Add / Remove Images</button>
                    </div>

                    <div>
                        <button className="denied-button" onClick={() => {
                            if (editShowing != archiveEvent) {
                                setEditShowing(archiveEvent);
                            } else {
                                setEditShowing(nothing);
                            }
                        }}>Archive Event</button>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div>
                <div className='back-button'>
                    <button className='denied-button' onClick={() => props.setEditing(false)}><i class="fa-solid fa-left-long"></i> Go Back</button>
                </div>
                <div className='application-header'>
                    <h1 className="header">Viewing Event | {name} | {date}</h1>
                </div>

                {renderEventEditControl()}

                {renderEventEdit()}
            </div>
        </>
    )
}

export default EmployeeEventEdit;