import React, { Component } from "react";

class ApplicationLoginPrompt extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="prompt">
                    <h1 className="header">Before you Continue</h1>
                    <p>We encourage you to make a user account before submmitting an application.</p>
                    <p>Making an account is quick, easy, and free. With an account you will be able to track the status of your application in real time, and it makes it easier for us to communicate with you when you need help.</p>
                    <p>However if you are unable to create an account or for any reason unwilling, we understand and you can continue as a guest.</p>

                    <ul className="prompt-buttons">

                        <li>
                            <button onClick={this.props.toggleGuest} className='approved-button'>Continue as Guest</button>
                        </li>

                        <li>
                            <form action="/login">
                                <button action="submit" className='approved-button'>Create an Account</button>
                            </form>
                        </li>

                    </ul>
                </div>
            </>
        )
    }
}

export default ApplicationLoginPrompt;