import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';

class FindForm extends Component {
    constructor(props) {
        super(props);

        this.state = { fName: "", lName: "", token: "", application: null, loading: false, error: false, errorMsg: "" }

    }

    onFirstNameInput = (event) => {
        this.setState({ fName: event.target.value, error: false });
    }

    onLastNameInput = (event) => {
        this.setState({ lName: event.target.value, error: false });
    }

    onTokenInput = (event) => {
        this.setState({ token: event.target.value, error: false });
    }

    onSubmit = (event) => {
        event.preventDefault();
        if (this.state.fName.length === 0 || this.state.lName.length === 0 || this.state.token.length === 0) {
            this.setState({ error: true, errorMsg: "Please input all requested information!"})
        }
        this.setState({ loading: true });

        axios.post('/api/search-for-application',
            {
                withCredentials: true,
                data: {
                    firstName: this.state.fName,
                    lastName: this.state.lName,
                    token: this.state.token,
                }
            }).then((res) => {
                if (res.data.status) {
                    this.props.onApplicationFound(res.data.application);
                    this.setState({ loading: false, error: false });
                } else {
                    this.setState({ loading: false, error: true, errorMsg: res.data.message });
                }
            })

    }

    renderErrorMessage() {
        if (this.state.error) {
            return <p className='error-message'>{this.state.errorMsg}</p>
        }
    }

    render() {
        return (
            <>
                <div className='find-form'>
                    <div>
                        <p>Please input the First and Last name of the applicant, along with their unique application token.</p>
                    </div>

                    <form onSubmit={this.onSubmit}>
                        <div className='find-area-inputs'>
                            <label for="fName">
                                First Name:  {this.state.fName.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id="fName" value={this.state.fName} onChange={this.onFirstNameInput} />
                            </label>

                            <label for="lName">
                                Last Name:  {this.state.lName.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id="lName" value={this.state.lName} onChange={this.onLastNameInput} />
                            </label>

                            <label for="token">
                                Token:  {this.state.token.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id="token" value={this.state.token} onChange={this.onTokenInput} />
                            </label>
                        </div>

                        {this.renderErrorMessage()}
                        <button className='search-button' action='submit'>Find Application <i class="fa-solid fa-magnifying-glass"></i></button>
                    </form>



                </div>
            </>
        )
    }
}

export default FindForm;