import React, { Component } from 'react';

import NewsletterSignupForm from "../../General/NewsletterSignupForm";

class UserNewsletterPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = { clicked: false }
    }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked });
    }

    renderSignupForm() {
        if (this.state.clicked) {
            return (
                <NewsletterSignupForm />
            )
        }
    }

    render() {
        return (
            <>
                <div className='newsletter-prompt-container'>
                    <h1 className='header'>{this.props.width > 780 ? "We Noticed You're Not Signed Up for Our Newsletter" : "Signup For Our Newsletter"}</h1>
                    <p>We understand if you're not interested, however if you'd like to be able to get real time updates whenever there are events or fundraisers please consider signing up.</p>
                    <button className='approved-button' onClick={this.handleClick}>Signup for Newsletter</button>
                    {this.renderSignupForm()}
                </div>

            </>
        )
    }
}

export default UserNewsletterPrompt;