export const BleedingDisorderTypeOptions = [
    { value: "Hemophilia A", label: "Hemophilia A (Factor 8)" },
    { value: "Hemophilia B", label: "Hemophilia B (Factor 9)" },
    { value: "Hemophilia C", label: "Hemophilia C (Factor 11)" },
    { value: "Von Willebrand Disease", label: "Von Willebrand Disease (VWD)" },
    { value: "Factor 1 Deficiency", label: "Factor 1 Deficiency" },
    { value: "Factor 2 Deficiency", label: "Factor 2 Deficiency" },
    { value: "Factor 5 Deficiency", label: "Factor 5 Deficiency" },
    { value: "Factor 7 Deficiency", label: "Factor 7 Deficiency" },
    { value: "Factor 10 Deficiency", label: "Factor 10 Deficiency" },
    { value: "Factor 12 Deficiency", label: "Factor 12 Deficiency" },
    { value: "Factor 13 Deficiency", label: "Factor 13 Deficiency" },
    { value: "Other", label: "Other (Please Specify in Details)" },
];