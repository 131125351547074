import React, { useState } from 'react';
import { Route, Routes } from "react-router-dom";
import axios from 'axios';

import './App.css';

// Import Routes

import Home from './routes/Home';
import About from './routes/About';
import Apply from './routes/Apply';
import Find from './routes/Find';
import Donate from './routes/Donate';
import Contact from './routes/Contact';
import Join from './routes/Join';
import Report from './routes/Report';
import Login from './routes/Login';
import Events from './routes/Events';
import Dashboard from './routes/Dashboard';
import Sisterhood from './routes/Sisterhood';
import Recover from './routes/Recover';


// Navbar and Footer

import Navbar from './components/General/Navbar';
import Footer from "./components/General/Footer";
import LoadingScreen from './components/General/LoadingScreen';

function useFetchData() {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  React.useEffect(() => {
    if (!userData) {
      setLoading(true);
      axios.get('/api/user', { withCredentials: true })
        .then((res) => {
          setUserData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        })
    }

  }, []);
  return { loading, userData };
}

function App() {
  const { loading, userData } = useFetchData();

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <div className="app">

      <nav>
        <Navbar userData={userData ? userData : null} />
      </nav>

      <main>
        <Routes>
          <Route path="/" element={<Home userData={userData} />} />
          <Route path="/about" element={<About userData={userData} />} />
          <Route path="/apply" element={<Apply userData={userData} />} />
          <Route path="/find" element={<Find userData={userData} />} />
          <Route path="/donate" element={<Donate userData={userData} />} />
          <Route path="/contact" element={<Contact userData={userData} />} />
          <Route path="/join" element={<Join userData={userData} />} />
          <Route path="/report" element={<Report userData={userData} />} />
          <Route path="/login" element={<Login userData={userData} />} />
          <Route path="/events" element={<Events userData={userData} />} />
          <Route path="/dashboard" element={<Dashboard userData={userData ? userData : null} />} />
          <Route path="/sisterhood" element={<Sisterhood userData={userData} />} />
          <Route path="/recover" element={<Recover userData={userData} />} />
        </Routes>
      </main>

      <footer>
        <Footer />
      </footer>

    </div>

  );
}

export default App;