import React from 'react';
import { Fade } from 'react-reveal';
import { Helmet } from 'react-helmet';

import "./DonateStyles.css";

function Donate() {

    return (
        <>
            <Helmet>
                <title>Donate | Hearts Unite</title>
                {/* Limits User Zoom */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <meta name="description" content="Hearts Unite to Give Support (HUGS) is a non-profit organization built on helping others through kind donations. By donating you can help assist us in our goal." />
                <meta property="og:title" content="Contact | Hearts Unite" />
                <meta property="og:image" content="/images/hugs-logo.png" />
            </Helmet>
            <div className='donate'>
                <Fade>
                    <div className='page-header'>
                        <h1 className='header'>Donate</h1>
                    </div>
                </Fade>

                <div className='donation-flexbox'>
                    <div>
                        <h1 className='header'>Donations Help Keep Us Going</h1>
                        <p>Hearts Unite to Give Support is a non-profit organization built on helping others through kind donations. </p>
                        <p>Donations through the website are handled through Paypal, so you can be assured that they will be handled securely.</p>
                        <form action="https://www.paypal.com/donate" method="post" target="_top">
                            <input type="hidden" name="hosted_button_id" value="GGU5K3RHKFTVA" />
                            <button className='donation-button' name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button">Click Here to Donate</button>
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                    </div>

                    <div>
                        <img src='/images/hugs_23.png' alt='person reassuring another person' className='donation-image'></img>
                    </div>
                </div>




            </div>
        </>
    )
}

export default Donate;