import React, { useState } from "react";
import axios from "axios";

import { Fade } from "react-reveal";

import { InputText } from 'primereact/inputtext';

const login = 0;
const signup = 1;

function LoginPasswordRecovery(props) {
    const [email, setEmail] = useState("");
    const [verifyEmail, setVerifyEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [errorSending, setErrorSending] = useState(false);
    const [errorSendingMessage, setErrorSendingMessage] = useState("");

    const verifyEmailInput = () => {
        if (email.includes('@') && email.includes('.')) {
            return true;
        }
        return false;
    }

    const handleRecover = (event) => {
        event.preventDefault();
        axios.post('/api/send-recovery-email', {
            data: {
                email: email,
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            withCredentials: true,
        }).then((res) => {
            if (res.data.status) {
                setEmailSent(true);
            } else {
                setErrorSending(true);
                setErrorSendingMessage(res.data.message);
            }
        })
    }

    const renderRecoverButton = () => {
        if (verifyEmailInput && email === verifyEmail) {
            return <button className="login-button approved-button" onClick={handleRecover}>Recover Password</button>
        } else {
            return <button className="login-button denied-button" disabled>Recover Password</button>
        }
    }

    const renderErrorMessage = () => {
        if (errorSending) {
            return (
                <Fade bottom>
                    <li>
                        <h3 className="error-text">{errorSendingMessage}</h3>
                    </li>
                </Fade>
            )
        }
    }

    if (!emailSent) {
        return (
            <>
                <div className="login-box">
                    <h1 className="header">Recover Password</h1>
                    <form>
                        <label>
                            Email: {verifyEmailInput() ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                            <InputText value={email} onChange={(event) => setEmail(event.target.value)} />
                        </label>

                        <label>
                            Verify Email: {email != verifyEmail || verifyEmail.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText value={verifyEmail} onChange={(event) => setVerifyEmail(event.target.value)} />
                        </label>
                        <div>
                            {renderErrorMessage()}
                        </div>

                        <div className="login-buttons">
                            <div>
                                {renderRecoverButton()}
                            </div>

                            <div>
                                <button className="login-button all-button" onClick={() => props.setShowing(login)}>Return to Login</button>
                            </div>
                        </div>

                    </form>

                    <p>Don't Have an Account?</p>
                    <button className="login-toggle-button all-button"
                        type="button"
                        onClick={() => props.setShowing(signup)}>
                        Signup <i class="fa-solid fa-right-to-bracket"></i>
                    </button>

                </div>
            </>
        )
    }

    return (
        <>
            <div className="login-box">
                <h2>Recover Password</h2>
                <h3>Your Recovery Request has Been Sent</h3>
                <p>To continue the recovery process, please click the link below.</p>
                <form action="/recover">
                    <button className="approved-button" action="submit">Click here to Continue</button>
                </form>

            </div>
        </>
    )

}

export default LoginPasswordRecovery;