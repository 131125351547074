import React, { Component } from "react";

class DashboardWelcome extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="page-header">
                <h1 className="header">Welcome Back {this.props.fName} </h1>
            </div>

        )
    }
}

export default DashboardWelcome;