import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { Fade } from 'react-reveal';

// Import Custom Styles
import "./ApplyStyles.css";

// Custom Components
import ApplicationForm from '../components/Apply/ApplicationForm';
import ApplicationLoginPrompt from '../components/Apply/ApplicationLoginPrompt';

function Apply(props) {
    const userData = props.userData;
    const [guest, setGuest] = useState(false);
    const [headerShowing, setHeaderShowing] = useState(true);

    React.useEffect(() => {
        document.title = "Apply | Hearts Unite";
    }, []);

    const toggleGuest = () => {
        setGuest(true);
    }

    const renderApplicationForm = () => {
        if (userData || guest) {
            return (
                <ApplicationForm userData={userData} guest={guest} />
            );
        } else {
            return (
                <ApplicationLoginPrompt toggleGuest={toggleGuest} />
            );
        }
    }

    const renderHeader = () => {
        if (headerShowing) {
            return (
                <Fade>
                    <div className="page-header">
                        <h1 className='header'>Apply for Assistance</h1>
                    </div>
                </Fade>
            )
        }
    }

    return (
        <div>
            <Helmet>
                <title>Apply | Hearts Unite</title>
                {/* Limits User Zoom */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <meta name="description" content="Apply for financial assistance from Hearts Unite to Give Support (HUGS), a non-profit organization dedicated to helping individuals and families during difficult times. Our mission is to reduce stress by providing advocacy, education, and support to those in need. Submit your application today and let us lend a helping hand in your time of need." />
                <meta property="og:title" content="Apply | Hearts Unite" />
                <meta property="og:image" content="/images/hugs-logo.png" />
            </Helmet>
            <div className="apply">
                <div className="apply-content">

                    {renderHeader()}

                    {renderApplicationForm()}

                </div>

            </div>



        </div>
    )
}

export default Apply;