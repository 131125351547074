import React, { Component } from "react";
import Fade from 'react-reveal/Fade';
import axios from "axios";

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

axios.defaults.withCredentials = true;

const forgot = 2;

class LoginBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: props.loginEmail, password: props.loginPassword, error: false, errorMsg: "",
        }

    }

    handleSubmit = (event) => {

        event.preventDefault();

        axios.post('/api/login', {
            withCredentials: true,
            data: {
                email: this.state.email,
                password: this.state.password,
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                window.location.reload(false);
            } else {
                this.setState({ error: true, errorMsg: res.data.message });
            }
        })
            .catch((err) => {
                this.setState({ errorMsg: err.message, error: true });
            });
    }

    verifyEmail = () => {
        if (this.state.email.includes('@') && this.state.email.includes('.')) {
            return true;
        }
        return false;
    }

    onEmailInput = (event) => {
        this.setState({ email: event.target.value });
        this.props.handleLoginEmail(this.state.email);
    }

    onPasswordInput = (event) => {
        this.setState({ password: event.target.value });
        this.props.handleLoginPassword(this.state.password);
    }

    renderError() {
        if (this.state.error) {
            return (
                <Fade bottom>
                    <h3 className="error-text">{this.state.errorMsg}</h3>
                </Fade>
            )
        } else {
            return "";
        }

    }

    renderLoginButton() {
        if (this.verifyEmail() > 0 && this.state.password.length > 0) {
            return (<>
                <button className="login-button approved-button" type="submit">Submit <i class="fa-solid fa-check" /></button>
            </>)
        } else {
            return (<>
                <button className="login-button denied-button" type="submit" disabled>Submit <i class="fa-thin fa-x" /></button>
            </>)
        }


    }

    render() {
        return (
            <>
                <div className="login-box">
                    <h1 className="header">Login to Account</h1>

                    <form onSubmit={this.handleSubmit}>
                        <label>
                            Email: {this.verifyEmail() ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                            <InputText value={this.state.email} onChange={this.onEmailInput} />
                        </label>

                        <label>
                            Password: {this.state.password.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <Password type="password" name="password" value={this.state.password} onChange={this.onPasswordInput} toggleMask={true} />
                        </label>

                        {this.renderError()}

                        <div className="login-buttons">
                            <div>
                                {this.renderLoginButton()}
                            </div>

                            <div>
                                <button className="login-button pending-button" type="button" onClick={() => this.props.setShowing(forgot)}>Forgot Password? <i class="fa-solid fa-thought-bubble"></i></button>
                            </div>
                        </div>


                    </form>



                </div>

            </>
        )
    }
}

export default LoginBox;