export const TutorialData = [
    {
        "info": "Sign up for a user account"
    },
    {
        "info": "Fill out a quick application"
    },
    {
        "info": "Help is on the way"
    }

]