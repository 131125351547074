import React from "react";

export default function ResponseTemplate(props) {
    const status = props.status;
    const message = props.message;
    const token = props.token;
    const firstName = props.firstName;
    var headerMessage = "";
    if (status === "Approved") {
        headerMessage = "Your Hearts Unite to Give Support application was approved!";
    } else {
        headerMessage = "Unfortunately, your Hearts Unite to Give Support application was denied.";
    }

    return (
        <>
            <div className="response-template">
                <h2 className="header">Decision On Your Hearts Unite Application - {token}</h2>
                <p>Hello {firstName},</p>
                <p>{headerMessage}</p>
                <p>{message}</p>
                <p>From,</p>
                <p><b>The Hearts Unite to Give Support Team</b></p>
            </div>
        </>
    )
}