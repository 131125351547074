import React, { useState } from "react";
import axios from "axios";

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

function RecoverForm() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [token, setToken] = useState("");
    const [recovered, setRecovered] = useState(false);
    const [errorSending, setErrorSending] = useState("");
    const [errorSendingMessage, setErrorSendingMessage] = useState("");

    const verifyPassword = () => {
        const symbols = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '+', '=', '[', ']', "'", '"', ';', ':', ',', '.', '/', '<', '>', '?', '`', '~'];
        const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        const caps = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
            'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        var validSymbols = false;
        var validNumbers = false;
        var validCaps = false;

        for (var i = 0; i < symbols.length; i++) {
            if (password.includes(symbols[i])) {
                validSymbols = true;
                break;
            }
        }

        if (validSymbols) {
            for (var i = 0; i < numbers.length; i++) {
                if (password.includes(numbers[i])) {
                    validNumbers = true;
                    break;
                }
            }
        } else {
            setErrorSending(true);
            setErrorSendingMessage("Password Must Contain 1 Symbol, Uppercase Letter, and Number!");
            return false;
        }

        if (validNumbers) {
            for (var i = 0; i < caps.length; i++) {
                if (password.includes(caps[i])) {
                    validCaps = true;
                    break;
                }
            }
        } else {
            setErrorSending(true);
            setErrorSendingMessage("Password Must Contain 1 Symbol, Uppercase Letter, and Number!");
            return false;
        }

        if (validCaps) {
            return true;
        } else {
            setErrorSending(true);
            setErrorSendingMessage("Password Must Contain 1 Symbol, Uppercase Letter, and Number!");
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        verifyPassword();

        axios.post('/api/recover-password', {
            data: {
                email: email,
                password: password,
                token: token,

            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            withCredentials: true,
        }).then((res) => {
            if (res.data.status) {
                setRecovered(true);
            } else {
                setErrorSending(true);
                setErrorSendingMessage(res.data.message);
            }
        })
    }

    const renderSubmitButton = () => {
        if (password.length != 0 && password.length < 50 && password === passwordConfirm && email.length != 0 && email.length < 50
            && token.length === 10) {
            return <button className="approved-button"
                onClick={handleSubmit}>Submit Recovery</button>
        }

        return <button className="denied-button" disabled>Submit Recovery</button>
    }

    const renderErrorMessage = () => {
        if (errorSending) {
            return <h2 className="error">{errorSendingMessage}</h2>
        }
    }

    if (!recovered) {
        return (
            <>
                <div className="page-header">
                    <h1 className="header">Recover Password</h1>
                </div>
                <div className="recovery-form">
                    <form className="main-form">
                        <div>
                            <p>Please input the following information for your
                                user account and input the Recovery Code sent to your email.</p>
                            <p>Haven't got a recovery code?</p>
                            <p>Go To Login and Click 'Forgot Password'</p>
                        </div>

                        <label>
                            Email: {email.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText value={email} onChange={(event) => setEmail(event.target.value)} />
                        </label>

                        <label>
                            Recovery Code: {token.length !== 10 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText value={token} onChange={(event) => setToken(event.target.value)} />
                        </label>

                        <label>
                            New Password: {password.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <Password value={password} onChange={(event) => setPassword(event.target.value)} />
                        </label>

                        <label>
                            Confirm New Password: {password.length > 0 && password === passwordConfirm ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                            <Password value={passwordConfirm} onChange={(event) => setPasswordConfirm(event.target.value)} />
                        </label>

                        {renderErrorMessage()}
                        <div className="recovery-buttons">
                            {renderSubmitButton()}
                            <form action="/login">
                                <button class='approved-button' action="submit">Back to Login</button>
                            </form>
                        </div>

                    </form>

                </div>
            </>
        )
    }

    return (
        <>
            <div className="page-header">
                <h1 className="header">Recover Password</h1>
            </div>

            <div className="recovery-form">
                <p>Your Password has been reset, click the button below to continue to login.</p>
                <div className="recovery-success-button">
                    <form action="/login">
                        <button class='approved-button' action="submit">Back to Login</button>
                    </form>
                </div>
            </div>

        </>

    )

}

export default RecoverForm;