import React from "react";

export default function EmailTemplate(props) {
    const message = props.message;
    const token = props.token;
    const firstName = props.firstName;

    return (
        <>
            <div className="response-template">
                <h2 className="header">Message About Your Hearts Unite Application - {token}</h2>
                <p>Hello {firstName},</p>
                <p>{message}</p>
                <p>This is a no-reply email, please do not respond to it.</p>
                <p>From,</p>
                <p><b>The Hearts Unite to Give Support Team</b></p>
            </div>
        </>
    )
}