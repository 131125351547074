import React, { useState, useLayoutEffect } from 'react';
import axios from 'axios';

import Select from 'react-select';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import { StateOptions } from "../Apply/StateOptions";

const timeOptions = [
    { value: "Morning", label: "Morning" },
    { value: "Afternoon", label: "Afternoon" },
    { value: "Evening", label: "Evening" },
]

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function AmbassadorForm(props) {
    const [width, height] = useWindowSize();

    // General Information
    const [firstName, setFirstName] = useState(props.userData ? props.userData.firstName : "");
    const [lastName, setLastName] = useState(props.userData ? props.userData.lastName : "");
    const [email, setEmail] = useState(props.userData ? props.userData.email : "");
    const [birthday, setBirthday] = useState("");
    const [phone, setPhone] = useState("");
    const [income, setIncome] = useState("");
    const [gender, setGender] = useState(null);

    // Address Information
    const [streetAddress, setStreetAddress] = useState("");
    const [streetAddress2, setStreetAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState(null);
    const [zip, setZip] = useState("");

    // Application Specific Information
    const [callTime, setCallTime] = useState("");
    const [employer, setEmployer] = useState("");
    const [interestReason, setInterestReason] = useState("");
    const [skills, setSkills] = useState("");
    const [otherCommitments, setOtherCommitments] = useState("");
    const [connections, setConnections] = useState("");
    const [otherExperience, setOtherExperience] = useState("");
    const [otherInvolvement, setOtherInvolvement] = useState("");
    const [time, setTime] = useState("");
    const [otherInformation, setOtherInformation] = useState("");
    const [socialMediaComfort, setSocialMediaComfort] = useState(null);
    const [showOnWebsite, setShowOnWebsite] = useState(null);


    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        axios.post('/api/ambassador-application', {
            data: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                streetAddress: streetAddress,
                streetAddress2: streetAddress2,
                city: city,
                state: state.value,
                zip: zip,
                callTime: callTime.value,
                employer: employer,
                interestReason: interestReason,
                connections: connections,
                skills: skills,
                otherCommitments: otherCommitments,
                otherExperience: otherExperience,
                otherInvolvement: otherInvolvement,
                time: time,
                socialMediaComfort: socialMediaComfort,
                showOnWebsite: showOnWebsite,
                otherInformation: otherInformation,
            },
            withCredentials: true,
        }).then((res) => {
            if (res.data.status) {
                setSubmitted(true);
            } else {
                setError(true);
                setErrorMessage("Error Submitting Application");
            }
        })
    }

    const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

    const verifyIsNumber = (input) => {
        for (var i = 0; i < input.length; i++) {
            if (!numbers.includes(input[i])) {
                return false;
            }
        }
        return true;
    }


    // General Verifications

    const verifyFirstName = () => {
        if (firstName.length < 2 || firstName.length > 20) {
            return false;
        }

        return true;
    }

    const verifyLastName = () => {
        if (lastName.length < 2 || lastName.length > 20) {
            return false;
        }

        return true;
    }

    const verifyBirthday = () => {
        if (birthday.length === 10) {
            return true;
        }

        return false;
    }

    const verifyEmail = () => {
        if (email.includes('@') && email.includes('.') && email.length > 6 && email.length < 45) {
            return true;
        }
        return false;
    }

    const verifyPhone = () => {
        if (phone.length === 14) {
            return true;
        }

        return false;
    }

    // Address Verification

    const verifyStreetAddress = () => {
        if (streetAddress.length > 0 && streetAddress.length < 50) {
            return true;
        }

        return false;
    }

    const verifyCity = () => {
        if (city.length > 0 && city.length < 30) {
            return true;
        }

        return false;
    }

    const verifyZip = () => {
        if (zip.length === 5 && verifyIsNumber(zip)) {
            return true;
        }

        return false;
    }

    const selectStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? "bold" : "normal",
            fontSize: width <= 1260 ? "1.2rem" : "1.4rem",
            color: "#2d3436",
            backgroundColor: "#fff",
            "&:hover": {
                backgroundColor: "#f0f0f0", // Change to your desired hover background color
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? "#2d3436" : "#fff",
            margin: "12px 0 12px",
            height: width <= 1260 ? "48px" : "55px",
        }),
    };

    const checkCompleted = () => {
        if (verifyFirstName() && verifyLastName() && verifyEmail() &&
            verifyPhone() && verifyStreetAddress() && verifyCity() && callTime !== null && employer.length > 0
            && state !== null && interestReason.length > 0 && skills.length > 0 && otherCommitments.length > 0
            && connections.length > 0 && otherExperience.length > 0 && otherInvolvement.length > 0
            && time.length > 0 && socialMediaComfort !== null && showOnWebsite !== null) {
            return true;
        } else {
            return false;
        }
    }

    // Renders

    const renderSubmitButton = () => {
        if (checkCompleted()) {
            return (
                <button className="approved-button" onClick={handleSubmit}>Submit</button>
            );

        } else {
            return (
                <button className="denied-button" onClick={() => {
                    console.log(verifyFirstName() + " " + verifyLastName() + " " + verifyEmail() + " " +
            verifyPhone() + " " + verifyStreetAddress() + " " + verifyCity() + " " + callTime
            + " " + employer + " " + state + " " + interestReason
            + " " + skills + " " + otherCommitments > 0 + " " + connections.length > 0 
            + " " + otherExperience.length > 0 + " " + otherInvolvement.length > 0
            + " " + time.length > 0 + " " + socialMediaComfort !== null + " " + showOnWebsite !== null)
                }}>Submit</button>
            );
        }
    }

    const renderJoinForm = () => {
        if (!submitted) {
            return (
                <>
                    {/* Generic Info */}

                    <h1>Ambassador Application</h1>

                    <ul className='form-items'>

                        <li>
                            <label for="fName">
                                First Name: {!verifyFirstName() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id="fName" value={firstName} onChange={(event) => {
                                    setFirstName(event.target.value);
                                }} />
                            </label>
                        </li>

                        <li>
                            <label for="lName">
                                Last Name: {!verifyLastName() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id="lName" value={lastName} onChange={(event) => {
                                    setLastName(event.target.value);
                                }} />
                            </label>
                        </li>

                        <li>
                            <label for="email">
                                Email: {!verifyEmail() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id="email" value={email} onChange={(event) => {
                                    setEmail(event.target.value);
                                }} />
                            </label>
                        </li>

                        <li>
                            <label>
                                Phone: {!verifyPhone() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputMask mask="(999) 999-9999" slotChar="(___) ___-____" value={phone} onChange={(event) => setPhone(event.target.value)} />
                            </label>
                        </li>

                        <li>
                            <label for="streetAddress">
                                Street Address: {!verifyStreetAddress() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}

                                <InputText id='streetAddress' value={streetAddress} onChange={(event) => setStreetAddress(event.target.value)} />
                            </label>
                        </li>

                        <li>
                            <label for="streetAddress2">
                                Street Address 2:
                                <InputText id='streetAddress2' value={streetAddress2} onChange={(event) => setStreetAddress2(event.target.value)} />
                            </label>
                        </li>

                        <li>
                            <label for="city">
                                City: {!verifyCity() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id='city' value={city} onChange={(event) => setCity(event.target.value)} />
                            </label>
                        </li>

                        <li>
                            <label for="zip">
                                ZIP: {!verifyZip() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id='zip' value={zip} onChange={(event) => setZip(event.target.value)} />
                            </label>
                        </li>

                        <li>
                            <label>
                                State: {state ? <i class="fa-solid fa-check green-check"></i> : <i class="fa-thin fa-asterisk red-asterisk" />}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={state}
                                    onChange={(option) => setState(option)} options={StateOptions} />
                            </label>
                        </li>

                    </ul>

                    {/* Employer / Call Time */}
                    <div className='middle-row'>
                        <div>
                            <label for='employer'>
                                Employer / Organization / Industry:  {employer.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <InputText id="employer" value={employer} onChange={(event) => {
                                    setEmployer(event.target.value);
                                }} />
                            </label>
                        </div>

                        <div>
                            <label>
                                Best Time of Day to Recieve Calls: {callTime.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                                <Select
                                    styles={selectStyles}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    value={callTime}
                                    onChange={(option) => setCallTime(option)}
                                    options={timeOptions} />
                            </label>
                        </div>
                    </div>

                    {/* Longer Text Areas */}

                    <div className='textarea-section'>
                        <div>
                            <label for="interestReason">Why are you interested in representing our organization?</label>
                        </div>

                        <div>
                            <InputTextarea autoResize={true} rows={5} cols={30}
                                id="interestReason" value={interestReason} onChange={(event) => {
                                    setInterestReason(event.target.value);
                                }} />
                        </div>

                        <div>
                            <label for="skills">What skills, connections, resources, and expertise do you have to offer and are willing to use on behalf of HUGS?</label>
                        </div>

                        <div>
                            <InputTextarea autoResize={true} rows={5} cols={30}
                                id="skills" value={skills} onChange={(event) => {
                                    setSkills(event.target.value);
                                }} />
                        </div>

                        <div>
                            <label for="otherCommitments">What other volunteer commitments do you currently have (if any)?</label>
                        </div>

                        <div>
                            <InputTextarea autoResize={true} rows={5} cols={30}
                                id="otherCommitments" value={otherCommitments} onChange={(event) => {
                                    setOtherCommitments(event.target.value);
                                }} />
                        </div>

                        <div>
                            <label for="connections">What local connections or networks do you have that could benefit our organization?</label>
                        </div>

                        <div>
                            <InputTextarea autoResize={true} rows={5} cols={30}
                                id="connections" value={connections} onChange={(event) => {
                                    setConnections(event.target.value);
                                }} />
                        </div>

                        <label for="otherExperience">Do you have any fundraising experience? If so please describe the type and level of experience.</label>

                        <div>
                            <InputTextarea autoResize={true} rows={5} cols={30}
                                id="otherExperience" value={otherExperience} onChange={(event) => {
                                    setOtherExperience(event.target.value);
                                }} />
                        </div>

                        <div>
                            <label for="otherInvolvement">Have you been involved in any community or charity initiatives in the past?</label>
                        </div>

                        <div>
                            <InputTextarea autoResize={true} rows={5} cols={30}
                                id="otherInvolvement" value={otherInvolvement} onChange={(event) => {
                                    setOtherInvolvement(event.target.value);
                                }} />
                        </div>


                        <div>
                            <label for="time">What time commitment can you realistically dedicate to hosting events for our non-profit?</label>
                        </div>

                        <div>
                            <InputTextarea autoResize={true} rows={5} cols={30}
                                id="time" value={time} onChange={(event) => {
                                    setTime(event.target.value);
                                }} />
                        </div>

                        <div>
                            <label for="otherInformation">Is there any additional background information, skills, abilities, talents, connections, networks or passions you would like to share?</label>
                        </div>

                        <div>
                            <InputTextarea autoResize={true} rows={5} cols={30}
                                id="otherInformation" value={otherInformation} onChange={(event) => {
                                    setOtherInformation(event.target.value);
                                }} />
                        </div>

                        <div className='join-buttons-section'>
                            <div>
                                <label for="socialMedia">Are you comfortable using social media or other online platforms to promote events?</label>
                            </div>

                            <div className="apply-buttons-container">
                                <button className={socialMediaComfort === false ? "apply-button declined no" : "apply-button no"}
                                    onClick={() => {
                                        setSocialMediaComfort(false);
                                    }}>No</button>

                                <button className={socialMediaComfort === true ? "apply-button approved yes" : "apply-button yes"}
                                    onClick={() => {
                                        setSocialMediaComfort(true);
                                    }}>Yes</button>
                            </div>

                            <div>
                                <label for="socialMedia">Are you comfortable having your face and name listed on our website as an ambassador?</label>
                            </div>

                            <div className="apply-buttons-container">
                                <button className={showOnWebsite === false ? "apply-button declined no" : "apply-button no"}
                                    onClick={() => {
                                        setShowOnWebsite(false);
                                    }}>No</button>

                                <button className={showOnWebsite === true ? "apply-button approved yes" : "apply-button yes"}
                                    onClick={() => {
                                        setShowOnWebsite(true);
                                    }}>Yes</button>
                            </div>

                        </div >
                    </div>

                    <p>Please double check and make sure that all of the information you've entered is correct.
                        Once you are sure, click submit and we will process your application as soon as possible.</p>

                    {renderSubmitButton()}
                </>
            )
        }

        return (
            <>
                <h1 className='header'>Application Submitted</h1>
                <p>Thanks for your application! We have recieved it and our staff have been notified.</p>
                <p>Please wait patiently while we review the application and get back to you. If you have had any issues or concerns, please use the report function to let us know.</p>
                <p>For more urgent businesss matters, use the information located on the contact page.</p>
                <p>Thank you!</p>
            </>

        )
    }

    return (
        <>
            <div className='join-form'>
                {renderJoinForm()}
            </div>
        </>
    )
}