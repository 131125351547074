import React, { Component } from "react";

class FindRedirectPrompt extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="redirect-prompt">
                    <h1 className="header">You're Already Logged in {this.props.fName}</h1>
                    <p>We just wanted to let you know if you're searching for your own application, it's much easier to go to your Dashboard and view it from there.</p>
                    <p>If you're searching for someone else's application you can still do it, just click 'Continue' below.</p>

                    <div className="prompt-buttons">
                        <div className="prompt-button">
                            <form action='/dashboard'>
                                <button className="approved-button" type="submit">Go to Dashboard</button>
                            </form>
                        </div>
                        <div className="prompt-button">
                            <button className="approved-button" onClick={this.props.onPromptComplete}>Continue</button>
                        </div>
                    </div>



                </div>
            </>
        )
    }
}

export default FindRedirectPrompt;
