import React, { useState, useLayoutEffect } from "react";
import LegacyApplicationAdd from "./LegacyApplicationAdd";
import axios from "axios";

import { Fade } from "react-reveal";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";

const nothing = 0;
const add = 1;
const all = 2;
const search = 3;

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function LegacyApplicationControl(props) {
    const [width, height] = useWindowSize();
    const [applications, setApplications] = useState(props.applications);
    const [showing, setShowing] = useState(all);
    const [opened, setOpened] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [key, setKey] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const removeLocalLegacyApplication = (token) => {
        const applicationIndex = applications.findIndex(app => app.token === token);

        if (applicationIndex !== -1) {
            var updatedApplications = [...applications];
            updatedApplications.splice(applicationIndex, 1);
            setApplications(updatedApplications);
            props.updateParent(updatedApplications);
        }
    }

    const removeLegacyApplication = () => {
        axios.defaults.withCredentials = true;

        const formData = new FormData();
        formData.append('key', key);
        formData.append('token', opened);

        axios.post('/api/remove-legacy-application', formData, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                setError(false);
                removeLocalLegacyApplication(opened);
                setOpened("");
            } else {
                setError(true);
                setErrorMessage(res.data.message);
            }
        })
    }

    // Update Applications
    const updateLegacyApplications = (newApplication) => {

        // Update Parent State
        props.updateParent((prevApplications) => [...prevApplications, newApplication]);

        // Update Local State
        setApplications((prevApplications) => [...prevApplications, newApplication]);
    };

    const containsApplication = (application) => {
        for (var i = 0; i < searchResults.length; i++) {
            if (searchResults[i] === application) {
                return true;
            }
        }

        return false;
    }

    const onSearchInput = (event) => {
        // Local Variable to Protect Against Async
        const input = event.target.value.toLowerCase();
        var localResults = searchResults;
        // Update Info
        setUserInput(event.target.value);

        if (input.length > 0) {
            applications.forEach(application => {
                const fullName = application.firstName.toLowerCase() + " " + application.lastName.toLowerCase();
                if (fullName.includes(input) ||
                    application.token.toLowerCase().includes(input) ||
                    application.date.toLowerCase().includes(input)) {
                    if (!containsApplication(application)) {
                        localResults.push(application);
                    }
                } else {
                    localResults = localResults.filter(e => e !== application);
                }
            });
            setSearchResults(localResults);
        } else {
            setSearchResults([]);
        }

    }

    // Nav

    const onAddClick = () => {
        if (showing !== add) {
            setOpened("");
            setShowing(add);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    const onAllClick = () => {
        if (showing !== all) {
            setOpened("");
            setShowing(all);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    const onSearchClick = () => {
        if (showing !== search) {
            setOpened("");
            setShowing(search);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    const onApplicationClick = (token) => {
        setOpened(token);
    }

    // Renders

    const renderControl = () => {
        return (
            <div className='application-control'>
                <div className='legacy-control'>

                    <button className='approved-button'
                        onClick={onAddClick}>
                        Add Application
                    </button>

                    <button className='all-button'
                        onClick={onAllClick}>
                        View All
                    </button>

                </div>

                <button className='search-button full-button'
                    onClick={onSearchClick}>
                    Search
                </button>
            </div>
        )
    }

    const renderError = () => {
        if (error) {
            return <h2 className="error">{errorMessage}</h2>
        }
    }

    const renderApplicationItem = (item) => {
        if (opened === item.token) {
            return (
                <li className="application-container"
                    key={item.token}>
                    <button className={item.status === "Approved" ? "application approved" :
                        item.status === "Pending" ? "application pending" :
                            item.status === "Denied" ? "application denied" : "application"}
                        onClick={() => onApplicationClick('')}>
                        <i class="fa-solid fa-angle-down" />
                        {" " + item.firstName + " " + item.lastName + " | " + item.date} </button>
                    <div className='legacy-application-info-list'>
                        <div className='application-info-item'>
                            <h2>First Name: </h2> <p>{item.firstName}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2>Last Name: </h2> <p>{item.lastName}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2>Amount: </h2> <p>{item.amount}</p>
                        </div>

                        <div className='application-info-item'>
                            <h2>Date: </h2> <p>{item.date}</p>
                        </div>

                    </div>
                    <h1 className="header">Remove Application</h1>
                    <div className="stats-remove-section">
                        <label>
                            Input Employee Key to Verify: {key.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <Password type="password" name="key" value={key} onChange={(event) => setKey(event.target.value)} toggleMask={true} />
                        </label>
                        {renderError()}
                        <button className={key.length > 0 ? 'view-full-button approved-button' :
                            'view-full-button denied-button'}
                            onClick={() => {
                                if (key.length > 0) {
                                    removeLegacyApplication();
                                }
                            }}
                        >Remove Legacy Application</button>
                    </div>

                </li>
            )
        } else {
            return (
                <li className='application-container' key={item.token}>
                    <button className={item.status === "Approved" ? "application approved" :
                        item.status === "Pending" ? "application pending" :
                            item.status === "Denied" ? "application denied" : "application"}
                        onClick={() => onApplicationClick(item.token)}>
                        <i class="fa-solid fa-angle-right" />
                        {" " + item.firstName + " " + item.lastName + " | " + item.date}
                    </button>
                </li>
            )
        }
    }

    const renderResults = () => {
        if (userInput.length === 0) {
            return <h2>Start Typing to Get Started!</h2>
        }
        if (userInput.length > 0 && searchResults.length === 0) {
            return <h2>No Result Found</h2>
        } else {
            return (
                <ul className="applications-list">
                    <div className='applications-list-padding'>
                        {searchResults.map((item, index) => {
                            return (
                                <Fade>
                                    {renderApplicationItem(item)}
                                </Fade>
                            )
                        })
                        }
                    </div>
                </ul>
            )
        }
    }

    const renderSelection = () => {
        switch (showing) {
            case add:
                return <LegacyApplicationAdd updateApplications={updateLegacyApplications} />
            case all:
                return (
                    <>
                        <ul className="applications-list">
                            <div className='applications-list-padding'>
                                {applications.map((item, index) => {
                                    return (
                                        <Fade>
                                            {renderApplicationItem(item)}
                                        </Fade>
                                    )
                                })
                                }
                            </div>
                        </ul>
                    </>
                )

            case search:
                return (
                    <>
                        <div className="application-search">
                            <h1 className="header">Search for Application</h1>

                            <div className="search-input">
                                <InputText value={userInput} onInput={onSearchInput}></InputText>
                            </div>

                            <h1 className='header'>Results</h1>
                            {renderResults()}
                        </div>
                    </>
                )
        }
    }

    return (
        <>
            <div className='legacy-container'>
                <div className='back-button'>
                    <button className='denied-button' onClick={() => props.setShowing(nothing)}><i class="fa-solid fa-left-long"></i> Go Back</button>
                </div>
                <h1 className='header'>Legacy Applications</h1>
                {renderControl()}
                {renderSelection()}
            </div>
        </>
    )
}