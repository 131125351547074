import React, { useState } from "react";
import { Slide } from "react-reveal";
import { InputText } from 'primereact/inputtext';
import axios from "axios";

axios.defaults.withCredentials = true;

export default function LegacyApplicationAdd(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [amount, setAmount] = useState("");
    const [status, setStatus] = useState("");
    const [date, setDate] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    // API Calls

    const submitApplication = () => {
        axios.defaults.withCredentials = true;

        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('amount', amount);
        formData.append('date', date);
        formData.append('status', status);

        axios.post('/api/legacy-application', formData, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                setSubmitted(true);
                setError(false);
                props.updateApplications(res.data.application);

            } else {
                setError(true);
                setErrorMessage(res.data.message);
            }
        })

    }

    // Helper Functions

    const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

    const verifyIsNumber = (input) => {
        for (var i = 0; i < input.length; i++) {
            if (!numbers.includes(input[i])) {
                return false;
            }
        }
        return true;
    }

    const resetState = () => {
        setFirstName("");
        setLastName("");
        setAmount("");
        setStatus("");
        setDate("");
        setSubmitted(false);
        setError(false);
        setErrorMessage("");
    }

    // Verifications

    const verifyFirstName = () => {
        if (firstName.length < 2 || firstName.length > 20) {
            return false;
        }

        return true;
    }

    const verifyLastName = () => {
        if (lastName.length < 2 || lastName.length > 20) {
            return false;
        }

        return true;
    }

    const verifyAmount = () => {
        if (amount.length !== 0 && amount.length < 10 && verifyIsNumber(amount)) {
            return true;
        }
        return false;
    }

    const verifyStatus = () => {
        return status.length > 0 ? true : false;
    }

    const handleDateInput = (input) => {
        // Remove non-numeric characters
        const numericInput = input.replace(/[^0-9]/g, '');

        // Ensure input is not longer than 8 characters
        if (numericInput.length > 8) {
            return;
        }

        // Split input into month, day, and year parts
        const parts = [
            numericInput.substring(0, 2),
            numericInput.substring(2, 4),
            numericInput.substring(4, 8),
        ];

        // Filter out empty parts and join them with '/'
        const formattedDate = parts.filter(part => part).join('/');

        setDate(formattedDate);
    };



    const renderError = () => {
        if (error) {
            return <h2 className="error">{setErrorMessage}</h2>
        }
    }

    const renderSuccess = () => {
        if (submitted) {
            return (
                <div>
                    <Slide bottom>
                        <h1 className="success success-message">Legacy Application Submitted!</h1>
                    </Slide>
                </div>
            )
        }
    }

    return (
        <>
            <div className="legacy-add">
                <h1 className="header">Add Legacy Application</h1>
                <div className="legacy-row">
                    <label for='firstName'>
                        First Name:
                        <InputText id='firstName' value={firstName} onChange={(event) => {
                            if (error) {
                                setError(false);
                            }
                            setFirstName(event.target.value)
                        }} />
                    </label>

                    <label for='lastName'>
                        Last Name:
                        <InputText id='lastName' value={lastName} onChange={(event) => {
                            if (error) {
                                setError(false);
                            }
                            setLastName(event.target.value);
                        }} />
                    </label>

                    <label for='date'>
                        Date (MM/DD/YYYY):
                        <InputText id='date' value={date} onChange={(event) => {
                            if (error) {
                                setError(false);
                            }
                            handleDateInput(event.target.value);
                        }} />
                    </label>

                    <label for='amount'>
                        Amount (USD, rounded):
                        <InputText id='amount' value={amount} onChange={(event) => {
                            if (error) {
                                setError(false);
                            }
                            setAmount(event.target.value);
                        }} />
                    </label>
                </div>



                <div className='status-list'>
                    <div>
                        <button className={status === "Denied" ? 'denied-button not-approved' : 'denied-button'}
                            onClick={() => {
                                if (error) {
                                    setError(false);
                                }
                                setStatus("Denied");
                            }}>Denied</button>
                    </div>

                    <div>
                        <button className={status === "Approved" ? 'approved-button approved' : 'approved-button'}
                            onClick={() => {
                                if (error) {
                                    setError(false);
                                }
                                setStatus("Approved");
                            }}>Approved</button>
                    </div>
                </div>

                {renderError()}
                {renderSuccess()}
                <button className={verifyFirstName() &&
                    verifyLastName() && verifyAmount() && verifyStatus() ? 
                    'approved-button legacy-submit-button' : 'denied-button legacy-submit-button'}
                    onClick={() => {
                        if (!submitted) {
                            if (verifyFirstName() &&
                                verifyLastName() && verifyAmount() && verifyStatus()) {
                                submitApplication();
                            } else {
                                setError(true);
                                setErrorMessage('Missing Application Information!');
                            }
                        } else {
                            resetState();
                        }

                    }}>
                    {submitted ? 'Submit Another' : 'Submit'} {submitted ? <i class="fa-solid fa-rotate-right"/> : ''}
                </button>
            </div>
        </>
    )

}