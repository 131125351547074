import React, { useState } from "react";
import axios from "axios";

import { InputText } from 'primereact/inputtext';

import "./NewsletterSignupStyles.css";

axios.defaults.withCredentials = true;


function NewsletterSignupForm() {
    // States
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const successMsg = "Thanks for Signing Up!";

    // Events

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        axios.post("/api/newsletter-signup", formData, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.status) {
                setSubmitted(true);
            } else {
                setError(true);
                setErrorMsg(res.data.message);
            }
        })
    }

    // State Handling

    const onFirstNameInput = (event) => {
        setFirstName(event.target.value);
        setError(false);
        setErrorMsg("");
    }

    const onLastNameInput = (event) => {
        setLastName(event.target.value);
        setError(false);
        setErrorMsg("");
    }

    const onEmailInput = (event) => {
        setEmail(event.target.value);
        setError(false);
        setErrorMsg("");
    }

    // Verification

    const verifyFirstName = () => {
        if (firstName.length < 2 || firstName.length > 20) {
            return false;
        }

        return true;
    }

    const verifyLastName = () => {
        if (lastName.length < 2 || lastName.length > 20) {
            return false;
        }

        return true;
    }

    const verifyEmail = () => {
        if (email.includes('@') && email.includes('.') && email.length > 6 && email.length < 45) {
            return true;
        }
        return false;
    }

    // Renders

    const renderSubmitButton = () => {
        if (verifyFirstName() && verifyLastName() && verifyEmail()) {
            return <button className="approved-button" type="submit">Submit</button>
        }

        return <button className="denied-button" disabled onClick={() => {
            setError(true);
            errorMsg("Please Fill Out All Fields!")
        }}>Submit</button>
    }

    return (
        <>
            <div className="newsletter-signup">
                <h2 className="header">Signup For Our Newsletter</h2>
                <form onSubmit={handleSubmit}>
                    <div className="newsletter-signup-items">
                        <label for="fName">
                            First Name: {!verifyFirstName() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText id="fName" value={firstName} onChange={onFirstNameInput} />
                        </label>

                        <label for="lName">
                            Last Name: {!verifyLastName() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText id="lName" value={lastName} onChange={onLastNameInput} />
                        </label>

                        <label for="email">
                            Email: {!verifyEmail() ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                            <InputText id="email" value={email} onChange={onEmailInput} />
                        </label>

                    </div>

                    <h2 className={error ? "header error" : submitted ? "header success" : "header"}>
                        {error ? errorMsg : submitted ? successMsg : ""}
                    </h2>

                    {renderSubmitButton()}

                </form>

            </div>

        </>
    )
}

export default NewsletterSignupForm;