import React from 'react';
import { SponsorsData } from "./SponsorsData";

import Fade from 'react-reveal/Fade';

import "./SponsorsRowStyles.css";

function SponsorsRow() {

    return (
        <>
            <div className="sponsors-row">
                <h1 className='header'>Affiliated, With Love</h1>
                <ul className="sponsors-container">
                    {SponsorsData.map((item, index) => {
                        return (
                            <Fade delay={(index + 1) * 200}>
                                <li className="sponsors-item" key={index}>
                                    <img className="sponsors-image" src={item.source} alt={item.alt} />
                                </li>
                            </Fade>

                        )
                    })}
                </ul>

                <ul className="sponsors-container">
                    {SponsorsData.map((item, index) => {
                        return (
                            <Fade delay={(index + 1) * 200}>
                                <li className="sponsors-item" key={index}/>
                            </Fade>

                        )
                    })}
                </ul>

            </div>
        </>
    )
}

export default SponsorsRow;