export const MenuData = [
    {
        title: "Home",
        url: "/",
        cName: "nav-links",
        icon: "fa-solid fa-house-user"
    },
    {
        title: "Apply for Assistance",
        url: "/apply",
        cName: "nav-links",
        icon: "fa-solid fa-hand-holding-heart"
    },
    {
        title: "Find Application",
        url: "/find",
        cName: "nav-links",
        icon: "fa-solid fa-magnifying-glass"
    },

    {
        title: "Donate",
        url: "/donate",
        cName: "nav-links",
        icon: "fa-solid fa-money-bills",
    },
    {
        title: "Events",
        url: "/events",
        cName: "nav-links",
        icon: "fa-solid fa-calendar",
    },
    {
        title: "Sisterhood",
        url: "/sisterhood",
        cName: "nav-links",
        icon: "fa-solid fa-venus",
    },
    {
        title: "About Us",
        url: "/about",
        cName: "nav-links",
        icon: "fa-solid fa-heart",
    },
    {
        title: "Join the Team",
        url: "join",
        cName: "nav-links",
        icon: "fa-solid fa-people-group"
    },
    {
        title: "Contact Us",
        url: "contact",
        cName: "nav-links",
        icon: "fa-solid fa-envelope"
    },
    {
        title: "Report an Issue",
        url: "/report",
        cName: "nav-links",
        icon: "fa-solid fa-wrench"
    }
]