import React, { useState } from 'react';
import axios from 'axios';

import { Password } from 'primereact/password';

import './EventArchiveStyles.css';

export default function EventArchive(props) {
    const event = props.event;
    const [confirm, setConfirm] = useState(false);
    const [key, setKey] = useState("");

    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const onEventArchive = (event) => {
        // Use props.event for event database object here to prevent interacting with React event and causing errors
        event.preventDefault();
        const formData = new FormData();
        formData.append('key', key);
        formData.append('id', props.event._id);

        if (!props.event.archived) {
            axios.post('/api/archive-event', formData, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((res) => {
                if (res.data.status) {
                    setUpdated(true);
                    props.event.archived = true;
                } else {
                    setError(true);
                    setErrorMsg(res.data.message);
                }

            })
        } else {
            axios.post('/api/unarchive-event', formData, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((res) => {
                if (res.data.status) {
                    setUpdated(true);
                    props.event.archived = false;
                } else {
                    setError(true);
                    setErrorMsg(res.data.message);
                }

            })
        }


    }

    const onConfirmPress = () => {
        setConfirm(!confirm);
    }

    const renderError = () => {
        if (error) {
            return <h1 className='error-message header'>{errorMsg}</h1>
        }
    }

    const renderConfirmButton = () => {
        if (confirm) {
            return <button className='approved-button' onClick={onConfirmPress}>Confirmed</button>
        }

        return <button className='pending-button' onClick={onConfirmPress}>Click to Confirm</button>
    }

    const renderSubmitButton = () => {
        if (event.archived) {
            if (confirm && key.length > 0) {
                return <button className='approved-button' onClick={onEventArchive}>Verify & Unarchive</button>
            }

            return <button className='pending-button'>Verify & Unarchive</button>
        }

        if (confirm && key.length > 0) {
            return <button className='approved-button' onClick={onEventArchive}>Verify & Archive</button>
        }

        return <button className='pending-button'>Verify & Archive</button>
    }

    if (updated) {
        if (event.archived) {
            return (
                <>
                    <div className='event-archive'>
                        <h1 className='header'>Event Archived!</h1>
                        <p>The Event has successfully been archived and will no longer display on the website publically.</p>
                        <p>You will still be able to access information about it using the "View Archived Events" tab.</p>
                        <p>If you for some reason need to fully remove the event from the database, please contact technical support.</p>
                    </div>
                </>
            )
        }

        return (
            <>
                <div className='event-archive'>
                    <h1 className='header'>Event Unarchived!</h1>
                    <p>The Event has successfully been unarchived and will now display on the website publically.</p>
                    <p>To reverse this action, simply go to the "Active Events" tab and select it, and repeat this process.</p>
                    <p>If you for some reason need to fully remove the event from the database, please contact technical support.</p>
                </div>
            </>
        )

    }

    return (
        <>
            <div className='event-archive'>
                <h1 className='header'>{event.archived ? "Unarchive Event" : "Archive Event"}</h1>
                <div className='event-archive-confirm'>
                    {renderConfirmButton()}
                </div>

                <div className='event-archive-input'>
                    <label>
                        Input Employee Key to Verify: {key.length === 0 ? <i class="fa-thin fa-asterisk red-asterisk" /> : <i class="fa-solid fa-check green-check"></i>}
                        <Password type="password" name="key" value={key} onChange={(event) => setKey(event.target.value)} toggleMask={true} />
                    </label>
                </div>

                <div className='event-archive-error'>
                    <h2 className='header'>{renderError()}</h2>
                </div>

                <div className='event-archive-submit'>
                    {renderSubmitButton()}
                </div>

            </div>
        </>
    )
}