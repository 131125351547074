import React, { Component } from "react";

import SignupBox from "./SignupBox";
import LoginBox from "./LoginBox";
import LoginPasswordRecovery from "./LoginPasswordRecovery";

// Import custom styles
import "./LoginFormStyles.css";

import Fade from 'react-reveal/Fade';


const login = 0;
const signup = 1;
const forgot = 2;

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showing: login, loginEmail: "", loginPassword: "",
            signupEmail: "", signupPassword: "", signupPasswordConfirm: "", signupComplete: false,
            fName: "", lName: "",
        }

    }

    setShowing = (value) => {
        this.setState({ showing: value })
    }

    handleLoginEmail = (value) => {
        this.setState({ loginEmail: value });
    }

    handleLoginPassword = (value) => {
        this.setState({ loginPassword: value })
    }

    handleSignupEmail = (value) => {
        this.setState({ signupEmail: value });
    }

    handleSignupPassword = (value) => {
        this.setState({ signupPassword: value })
    }

    handleSignupPasswordConfirm = (value) => {
        this.setState({ signupPasswordConfirm: value })
    }

    handleFirstName = (value) => {
        this.setState({ fName: value })
    }

    handleLastName = (value) => {
        this.setState({ lName: value })
    }

    // Renders

    renderBox() {
        switch (this.state.showing) {
            case login:
                return (
                    <LoginBox loginEmail={this.state.loginEmail} loginPassword={this.state.loginPassword}
                        handleLoginEmail={this.handleLoginEmail} handleLoginPassword={this.handleLoginPassword} setShowing={this.setShowing} />
                )

            case signup:

                return (
                    <SignupBox signupEmail={this.state.signupEmail} signupPassword={this.state.signupPassword}
                        signupPasswordConfirm={this.state.signupPasswordConfirm} fName={this.state.fName} lName={this.state.lName}
                        handleSignupEmail={this.handleSignupEmail} handleSignupPassword={this.handleSignupPassword}
                        signupComplete={this.state.signupComplete} handleSignupPasswordConfirm={this.handleSignupPasswordConfirm}
                        handleFirstName={this.handleFirstName} handleLastName={this.handleLastName} setShowing={this.setShowing}/>
                )

            case forgot:

                return (
                    <LoginPasswordRecovery setShowing={this.setShowing} />
                )
        }
    }

    renderToggler() {
        switch (this.state.showing) {
            case login:
                return (
                    <>
                        <p>Don't Have an Account?</p>
                        <button className="login-toggle-button all-button"
                            type="button"
                            onClick={() => this.setShowing(signup)}>
                            Signup <i class="fa-solid fa-right-to-bracket"></i>
                        </button>
                    </>
                )

            case signup: {
                return (
                    <>
                        <p>Already Have an Account?</p>
                        <button className="login-toggle-button all-button"
                            type="button"
                            onClick={() => this.setShowing(login)}>
                            Login <i class="fa-solid fa-pen"></i>
                        </button>
                    </>
                )

            }

        }
    }


    render() {
        return (
            <>
                <div className="login-form">
                    <div className="img-box">

                        {/* <img className="login-image" 
                        src="/images/people-standing-clear.png" /> */}
                    </div>

                    <div className="login-box">
                        {this.renderBox()}
                    </div>

                    <br />

                    <div className="toggler">
                        <div className="box-toggle-container">
                            {this.renderToggler()}
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default LoginForm;