import React, { useEffect, useState } from "react";

const displayNone = 0;
const displayAll = 1;
const displayYearly = 2;

const MonthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

class Month {
    constructor(totalDays) {
        this.days = Array.from({ length: totalDays }, () => ({
            applications: [],
            reports: [],
            events: [],
            users: [],
        }));
    }
}

class Year {
    constructor(year) {
        this.year = year;
        this.months = {};
        for (let i = 0; i < 12; i++) {
            const monthName = MonthNames[i];
            const totalDays = new Date(year, i + 1, 0).getDate();
            this.months[monthName] = new Month(totalDays);
        }
    }
}

export default function EmployeeStats(props) {
    // States
    const [loading, setLoading] = useState(true);
    const [displaying, setDisplaying] = useState(displayAll);
    const [overallData, setOverallData] = useState(null);
    const [yearsData, setYearsData] = useState(null);
    const [yearSelected, setYearSelected] = useState(null);
    const [selectedYearData, setSelectedYearData] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedMonthName, setSelectedMonthName] = useState(null);
    const [selectedMonthData, setSelectedMonthData] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);

    // Props
    const users = props.users;
    const applications = props.applications;
    const legacyApplications = props.legacyApplications;
    const events = props.events;
    const reports = props.reports;

    // Date values for parsing data

    // Start date for while loop ( Jan 1st 2018 )
    const startDate = new Date(2018, 0, 1);

    const today = new Date();
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    function convertToMMDDYYYY(dateObject) {
        // Add 1 to the month since it's zero-indexed
        const month = (dateObject.getMonth() + 1).toString();
        const day = dateObject.getDate().toString();
        const year = dateObject.getFullYear().toString();
        return `${month}/${day}/${year}`;
    };

    const checkMonthData = (month) => {
        for (var i = 0; i < month.days.length; i++) {
            if (month.days[i].applications.length > 0 ||
                month.days[i].reports.length > 0 ||
                month.days[i].events.length > 0 ||
                month.days[i].users.length > 0) {
                return true;
            }
        }

        return false;
    }

    function removeLeadingZeros(dateString) {
        // Split the date string into an array of components
        var dateComponents = dateString.split('/');

        // Map through each component and parse it into an integer to remove leading zeros
        dateComponents = dateComponents.map(function (component) {
            return parseInt(component, 10);
        });

        // Join the components back together with '/' separator
        return dateComponents.join('/');
    }

    const checkDayData = (day) => {
        if (day.applications.length > 0 ||
            day.reports.length > 0 ||
            day.events.length > 0) {
            return true;
        }

        return false;
    }

    // Custom Year constructor for managing overall yearly data

    class yearData {
        constructor() {
            this.applications = [];
            this.legacyApplications = [];
            this.applicationData = {
                submitted: 0,
                approved: 0,
                denied: 0,
                sent: 0,
            }
            this.events = [];
            this.reports = [];
            this.users = [];
        }
    }

    useEffect(() => {
        // Merge Applications, Events, Reports, and Users into a single array
        const allData = [
            ...applications.map((item) => ({ ...item, type: 'application' })),
            ...legacyApplications.map((item) => ({ ...item, type: 'legacyApplication' })),
            ...events.map((item) => ({ ...item, type: 'event', date: item.dateAdded })),
            ...reports.map((item) => ({ ...item, type: 'report' })),
            ...users.map((item) => ({ ...item, type: 'user' })),
        ];

        var totalData = {
            applications: {
                submitted: 0,
                approved: 0,
                denied: 0,
                requested: 0,
                sent: 0,
            },

            // Legacy applications do not have a 'requested' stat available
            legacyApplications: {
                submitted: 0,
                approved: 0,
                denied: 0,
                sent: 0,
            },
            events: 0,
            reports: 0,
            users: 0,

            data2019: new yearData(),
            data2020: new yearData(),
            data2021: new yearData(),
            data2022: new yearData(),
            data2023: new yearData(),
            data2024: new yearData(),
        }

        const dataKeys = {
            2019: 'data2019',
            2020: 'data2020',
            2021: 'data2021',
            2022: 'data2022',
            2023: 'data2023',
            2024: 'data2024',
            // Add more years if needed
        };

        const approvedString = 'Approved';

        // Create Years object for storage
        const years = {};

        // Loop through dates
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const formattedCurrentDate = convertToMMDDYYYY(currentDate);

            const year = currentDate.getFullYear();

            if (!years[year]) {
                years[year] = new Year(year);
            }

            allData.forEach((item) => {
                switch (item.type) {
                    case 'application':
                        // Sort Data
                        if (item.issuedOn === formattedCurrentDate) {
                            const yearObject = years[year];
                            const monthName = MonthNames[currentDate.getMonth()];
                            const monthObject = yearObject.months[monthName];
                            const dayIndex = currentDate.getDate() - 1;
                            // Iterate over each year in the dataKeys object
                            for (const year in dataKeys) {
                                if (Object.hasOwnProperty.call(dataKeys, year)) {
                                    // Construct the key name dynamically
                                    const dataKey = dataKeys[year];

                                    // Check if the year exists in totalData
                                    if (totalData[dataKey]) {
                                        totalData[dataKey].applications.push(item);
                                        if (item.status === approvedString) {
                                            totalData[dataKey].applicationData.approved++;
                                            totalData[dataKey].applications.sent += item.amount;
                                        } else {
                                            totalData[dataKey].applications.denied++;
                                        }

                                        // Access the corresponding yearData object
                                        const yearData = totalData[dataKey].applicationData;

                                        // Update the yearData object based on the item's status
                                        if (item.status === approvedString) {
                                            yearData.approved++;
                                            yearData.sent += item.amount;
                                        } else {
                                            yearData.denied++;
                                        }
                                    }
                                }
                            }

                            monthObject.days[dayIndex].applications.push(item);
                        }
                        break;

                    case 'legacyApplication':
                        // Sort Data
                        if (removeLeadingZeros(item.date) === formattedCurrentDate) {
                            const yearObject = years[year];
                            const monthName = MonthNames[currentDate.getMonth()];
                            const monthObject = yearObject.months[monthName];
                            const dayIndex = currentDate.getDate() - 1;
                            for (const year in dataKeys) {
                                if (Object.hasOwnProperty.call(dataKeys, year)) {
                                    // Construct the key name dynamically
                                    const dataKey = dataKeys[year];

                                    // Check if the year exists in totalData
                                    if (totalData[dataKey]) {
                                        totalData[dataKey].legacyApplications.push(item);
                                        if (item.status === approvedString) {
                                            totalData[dataKey].applicationData.approved++;
                                            totalData[dataKey].applications.sent += item.amount;
                                        } else {
                                            totalData[dataKey].applications.denied++;
                                        }

                                        // Access the corresponding yearData object
                                        const yearData = totalData[dataKey].applicationData;

                                        // Update the yearData object based on the item's status
                                        if (item.status === approvedString) {
                                            yearData.approved++;
                                            yearData.sent += item.amount;
                                        } else {
                                            yearData.denied++;
                                        }
                                    }
                                }
                            }
                            monthObject.days[dayIndex].applications.push(item);
                        }
                        break;

                    case 'event':
                        // Sort Data
                        if (item.dateAdded === formattedCurrentDate) {
                            const yearObject = years[year];
                            const monthName = MonthNames[currentDate.getMonth()];
                            const monthObject = yearObject.months[monthName];
                            const dayIndex = currentDate.getDate() - 1;
                            for (const year in dataKeys) {
                                if (Object.hasOwnProperty.call(dataKeys, year)) {
                                    // Construct the key name dynamically
                                    const dataKey = dataKeys[year];
                                    // Check if the year exists in totalData
                                    if (totalData[dataKey]) {
                                        totalData[dataKey].events++;
                                    }
                                }
                            }
                            monthObject.days[dayIndex].events.push(item);
                        }
                        break;

                    case 'report':
                        // Sort Data
                        if (item.date === formattedCurrentDate) {
                            const yearObject = years[year];
                            const monthName = MonthNames[currentDate.getMonth()];
                            const monthObject = yearObject.months[monthName];
                            const dayIndex = currentDate.getDate() - 1;
                            for (const year in dataKeys) {
                                if (Object.hasOwnProperty.call(dataKeys, year)) {
                                    // Construct the key name dynamically
                                    const dataKey = dataKeys[year];
                                    // Check if the year exists in totalData
                                    if (totalData[dataKey]) {
                                        totalData[dataKey].reports++;
                                    }
                                }
                            }
                            monthObject.days[dayIndex].reports.push(item);
                        }
                        break;

                    case 'user':
                        // Sort Data
                        if (item.dateRegistered === formattedCurrentDate) {
                            const yearObject = years[year];
                            const monthName = MonthNames[currentDate.getMonth()];
                            const monthObject = yearObject.months[monthName];
                            const dayIndex = currentDate.getDate() - 1;
                            for (const year in dataKeys) {
                                if (Object.hasOwnProperty.call(dataKeys, year)) {
                                    // Construct the key name dynamically
                                    const dataKey = dataKeys[year];

                                    // Check if the year exists in totalData
                                    if (totalData[dataKey]) {
                                        totalData[dataKey].users++;
                                    }
                                }
                            }
                            monthObject.days[dayIndex].users.push(item);
                        }
                        break;
                }

            });

            // Handle Total Data outside the loop
            totalData.events += events.filter(event => event.dateAdded === formattedCurrentDate).length;
            totalData.reports += reports.filter(report => report.date === formattedCurrentDate).length;
            totalData.users += users.filter(user => user.dateRegistered === formattedCurrentDate).length;

            // Move to the next day
            currentDate.setDate(currentDate.getDate() + 1);
        }

        applications.forEach(app => {
            totalData.applications.submitted++;
            totalData.applications.requested += app.amount;
            if (app.status === 'Approved') {
                totalData.applications.approved++;
                totalData.applications.sent += app.amount;
            } else if (app.status === 'Denied') {
                totalData.applications.denied++;
            }
        });

        legacyApplications.forEach(app => {
            totalData.legacyApplications.submitted++;
            if (app.status === 'Approved') {
                totalData.legacyApplications.approved++;
                totalData.legacyApplications.sent += app.amount;
            } else if (app.status === 'Denied') {
                totalData.legacyApplications.denied++;
            }
        });

        setYearsData(years);
        setOverallData(totalData);
        setLoading(false);
    }, []);

    useEffect(() => {
        if (selectedMonth !== null && selectedMonth !== undefined) {
            const data = {
                // Application
                submitted: 0,
                approved: 0,
                denied: 0,
                requested: 0,
                sent: 0,

                // Events
                events: 0,

                // Reports
                reports: 0,

                // Users
                users: 0,
            }

            selectedMonth.days.forEach(day => {
                if (day.applications.length > 0) {
                    day.applications.forEach(application => {
                        data.submitted++;
                        data.requested += application.amount;
                        if (application.status === 'Approved') {
                            data.approved++;
                            data.sent += application.amount;
                        } else if (application.status === 'Denied') {
                            data.denied++;
                        }
                    })
                }

                if (day.events.length > 0) {
                    day.events.forEach(event => {
                        data.events++;
                    })
                }

                if (day.reports.length > 0) {
                    day.reports.forEach(report => {
                        data.reports++;
                    })
                }
                if (day.users.length > 0) {
                    data.users++;
                }
            })

            setSelectedMonthData(data);
        }
    }, [selectedMonth]);

    // Renders

    if (loading) {
        return (
            <>
                <div className='display-container'>
                    <h1 h1 className='header'>Statistics</h1>
                    <p>Generating Stats...</p>
                </div>
            </>
        )
    }

    const renderYearNav = () => {
        if (selectedYearData !== null) {
            return (
                <>
                    <div className="year-nav">
                        <div className="year-nav-month-buttons">
                            <button className={checkMonthData(yearsData[yearSelected].months.January)
                                ? 'stats-content-button' : 'denied-button'} onClick={() => {
                                    if (checkMonthData(yearsData[yearSelected].months.January)) {
                                        setSelectedMonth(yearsData[yearSelected].months.January);
                                        setSelectedMonthName('January');
                                        setSelectedDay(null);
                                    }
                                }}>January</button>
                            <button className={checkMonthData(yearsData[yearSelected].months.February)
                                ? 'stats-content-button' : 'denied-button'} onClick={() => {
                                    if (checkMonthData(yearsData[yearSelected].months.February)) {
                                        setSelectedMonth(yearsData[yearSelected].months.February);
                                        setSelectedMonthName('February');
                                        setSelectedDay(null);
                                    }
                                }}>February</button>
                            <button className={checkMonthData(yearsData[yearSelected].months.March)
                                ? 'stats-content-button' : 'denied-button'} onClick={() => {
                                    if (checkMonthData(yearsData[yearSelected].months.March)) {
                                        setSelectedMonth(yearsData[yearSelected].months.March);
                                        setSelectedMonthName('March');
                                        setSelectedDay(null);
                                    }
                                }}>March</button>
                            <button className={checkMonthData(yearsData[yearSelected].months.April)
                                ? 'stats-content-button' : 'denied-button'} onClick={() => {
                                    if (checkMonthData(yearsData[yearSelected].months.April)) {
                                        setSelectedMonth(yearsData[yearSelected].months.April);
                                        setSelectedMonthName('April');
                                        setSelectedDay(null);
                                    }
                                }}>April</button>
                            <button className={checkMonthData(yearsData[yearSelected].months.May)
                                ? 'stats-content-button' : 'denied-button'} onClick={() => {
                                    if (checkMonthData(yearsData[yearSelected].months.May)) {
                                        setSelectedMonth(yearsData[yearSelected].months.May);
                                        setSelectedMonthName('May');
                                        setSelectedDay(null);
                                    }
                                }}>May</button>
                            <button className={checkMonthData(yearsData[yearSelected].months.June)
                                ? 'stats-content-button' : 'denied-button'} onClick={() => {
                                    if (checkMonthData(yearsData[yearSelected].months.June)) {
                                        setSelectedMonth(yearsData[yearSelected].months.June);
                                        setSelectedMonthName('June');
                                        setSelectedDay(null);
                                    }
                                }}>June</button>
                            <button className={checkMonthData(yearsData[yearSelected].months.July)
                                ? 'stats-content-button' : 'denied-button'} onClick={() => {
                                    if (checkMonthData(yearsData[yearSelected].months.July)) {
                                        setSelectedMonth(yearsData[yearSelected].months.July);
                                        setSelectedMonthName('July');
                                        setSelectedDay(null);
                                    }
                                }}>July</button>
                            <button className={checkMonthData(yearsData[yearSelected].months.August)
                                ? 'stats-content-button' : 'denied-button'} onClick={() => {
                                    if (checkMonthData(yearsData[yearSelected].months.August)) {
                                        setSelectedMonth(yearsData[yearSelected].months.August);
                                        setSelectedMonthName('August');
                                        setSelectedDay(null);
                                    }
                                }}>August</button>
                            <button className={checkMonthData(yearsData[yearSelected].months.September)
                                ? 'stats-content-button' : 'denied-button'} onClick={() => {
                                    if (checkMonthData(yearsData[yearSelected].months.September)) {
                                        setSelectedMonth(yearsData[yearSelected].months.September);
                                        setSelectedMonthName('September');
                                        setSelectedDay(null);
                                    }
                                }}>September</button>
                            <button className={checkMonthData(yearsData[yearSelected].months.October)
                                ? 'stats-content-button' : 'denied-button'} onClick={() => {
                                    if (checkMonthData(yearsData[yearSelected].months.October)) {
                                        setSelectedMonth(yearsData[yearSelected].months.October);
                                        setSelectedMonthName('October');
                                        setSelectedDay(null);
                                    }
                                }}>October</button>
                            <button className={checkMonthData(yearsData[yearSelected].months.November)
                                ? 'stats-content-button' : 'denied-button'} onClick={() => {
                                    if (checkMonthData(yearsData[yearSelected].months.November)) {
                                        setSelectedMonth(yearsData[yearSelected].months.November);
                                        setSelectedMonthName('November');
                                        setSelectedDay(null);
                                    }
                                }}>November</button>
                            <button className={checkMonthData(yearsData[yearSelected].months.December)
                                ? 'stats-content-button' : 'denied-button'} onClick={() => {
                                    if (checkMonthData(yearsData[yearSelected].months.December)) {
                                        setSelectedMonth(yearsData[yearSelected].months.December);
                                        setSelectedMonthName('December');
                                        setSelectedDay(null);
                                    }
                                }}>December</button>
                        </div>

                        <button className={'stats-content-button year-all-button'} onClick={() => {
                            if (selectedMonth !== null) {
                                setSelectedMonth(null);

                            }
                        }
                        }>
                            All Yearly Data
                        </button>

                    </div>

                </>
            )
        } else {
            return (
                <>
                    <h1>No Statistics for {yearSelected}</h1>
                </>
            )
        }
    }

    const renderYearlyData = () => {
        if (yearSelected !== null && selectedMonth === null) {
            // Construct the data key dynamically
            const dataKey = `data${yearSelected}`;
    
            // Check if the data key exists in totalData
            if (overallData[dataKey]) {
                console.log(overallData[dataKey]);
                return (
                    <>
                        <h1 className="header">Data for {yearSelected}</h1>
                        <div className="month-data">
                            <div>
                                <h2>Applications Submitted: {overallData[dataKey].applications.length}</h2>
                            </div>
    
                            <div>
                                <h2>Application Approval Rate: {(overallData[dataKey].applicationData.approved / overallData[dataKey].applications.length).toFixed()}%</h2>
                            </div>
    
                            <div>
                                <h2>Assistance Given: ${overallData[dataKey].applicationData.sent.toLocaleString()}</h2>
                            </div>
    
                            <div>
                                <h2>Events Added: {overallData[dataKey].events}</h2>
                            </div>
    
                            <div>
                                <h2>Users Signed Up: {overallData[dataKey].users}</h2>
                            </div>
    
                            <div>
                                <h2>Reports Submitted: {overallData[dataKey].reports}</h2>
                            </div>
                        </div>
                    </>
                );
            } else {
                // Handle case when data for selected year is not available
                return <p>Data for {yearSelected} is not available.</p>;
            }
        }
    };

    const renderMonthData = () => {
        if (selectedMonth !== null && selectedMonth !== undefined &&
            selectedMonthData !== null) {
            return (
                <>
                    <h1 className="header">Data for {selectedMonthName} {yearSelected}</h1>
                    <div className="month-data">
                        <div>
                            <h2>Applications Submitted: {selectedMonthData.submitted}</h2>
                        </div>

                        <div>
                            <h2>Application Approval Rate: {selectedMonthData.submitted > 0 ? ((selectedMonthData.approved / selectedMonthData.submitted) * 100).toFixed() : 0}%</h2>
                        </div>

                        <div>
                            <h2>Assistance Given: ${selectedMonthData.sent.toLocaleString()}</h2>
                        </div>

                        <div>
                            <h2>Events Added: {selectedMonthData.events}</h2>
                        </div>

                        <div>
                            <h2>Users Signed Up: {selectedMonthData.users}</h2>
                        </div>

                        <div>
                            <h2>Reports Submitted: {selectedMonthData.reports}</h2>
                        </div>

                    </div>
                </>
            )

        }
    }

    const renderDayNav = () => {
        if (selectedMonth !== null && selectedMonth !== undefined) {
            return (
                <>
                    <h1 className="header">View Specific Dates</h1>
                    <div className="month-nav">
                        {selectedMonth.days.map((day, index) => {
                            return (
                                <>
                                    <button className={checkDayData(day) ? 'stats-content-button'
                                        : 'denied-button'}
                                        onClick={() => {
                                            if (selectedDay !== index) {
                                                if (checkDayData(day)) {
                                                    setSelectedDay(index);
                                                }
                                            } else {
                                                setSelectedDay(null);
                                            }
                                        }}>
                                        {index + 1}
                                    </button>
                                </>
                            )
                        })}
                    </div>
                </>
            )
        }
    }

    const renderDayData = () => {
        if (selectedDay !== null) {
            return (
                <>
                    <h1 className='header'>Data for {selectedMonthName} {selectedDay + 1}</h1>
                    <div className="day-data">
                        <div>
                            <h2>Applications Submitted: {selectedMonth.days[selectedDay].applications.length}</h2>
                        </div>

                        <div>
                            <h2>Events Added: {selectedMonth.days[selectedDay].events.length}</h2>
                        </div>

                        <div>
                            <h2>Users Signed Up: {selectedMonth.days[selectedDay].applications.length}</h2>
                        </div>
                    </div>
                </>
            )

        }
    }

    const renderSelectedYear = () => {
        if (displaying === displayAll) {
            if (overallData !== null) {
                return (
                    <>
                        <h1 className={'header'}>Overall Statistics</h1>
                        <div className="month-data">
                            <div>
                                <h2>Applications Submitted: {(overallData.applications.submitted + overallData.legacyApplications.submitted)}</h2>
                            </div>

                            <div>
                                <h2>Application Approval Rate: {overallData.applications.submitted > 0 && overallData.legacyApplications.submitted > 0 ?
                                    (((overallData.applications.approved + overallData.legacyApplications.approved) / (overallData.applications.submitted + overallData.legacyApplications.submitted)) * 100).toFixed() : 0}%</h2>
                            </div>

                            <div>
                                <h2>Assistance Given: ${(overallData.applications.sent + overallData.legacyApplications.sent).toLocaleString()}</h2>
                            </div>

                            <div>
                                <h2>Events Added: {overallData.events}</h2>
                            </div>

                            <div>
                                <h2>Users Signed Up: {overallData.users}</h2>
                            </div>

                            <div>
                                <h2>Reports Submitted: {overallData.reports}</h2>
                            </div>

                        </div>
                    </>
                )
            }
        }
        return (
            <>
                {renderYearNav()}
                {renderYearlyData()}
                {renderMonthData()}
                {renderDayNav()}
                {renderDayData()}
            </>
        )
    }

    return (
        <>
            <div>
                <div className='display-container'>
                    <h1 className='header'>Statistics</h1>
                    <h2>Section Currently Under Construction!</h2>
                    <button className="approved-button all-stats-button" onClick={() => {
                        if (displaying !== displayAll) {
                            setSelectedYearData(null);
                            setYearSelected(null);
                            setSelectedMonth(null);
                            setDisplaying(displayAll);
                        } else {
                            setDisplaying(displayNone);
                        }

                    }}>
                        All
                    </button>
                    <div className="stats-nav">

                        <button className="approved-button" onClick={() => {
                            // Reset Month Data to avoid unexpected behavior
                            setSelectedMonth(null);
                            setSelectedMonthData(null);
                            if (displaying === displayYearly && yearSelected === 2019) {
                                setSelectedYearData(null);
                                setYearSelected(null);
                                setDisplaying(displayAll);
                            } else {
                                if (yearSelected !== 2019) {
                                    setSelectedYearData(yearsData[2019]);
                                    setYearSelected(2019);
                                    setDisplaying(displayYearly);
                                } else {
                                    setSelectedYearData(null);
                                    setYearSelected(null);
                                    setDisplaying(displayNone);
                                }
                            }
                        }}>
                            2019
                        </button>

                        <button className="approved-button" onClick={() => {
                            // Reset Month Data to avoid unexpected behavior
                            setSelectedMonth(null);
                            setSelectedMonthData(null);
                            if (displaying === displayYearly && yearSelected === 2020) {
                                setSelectedYearData(null);
                                setYearSelected(null);
                                setDisplaying(displayAll);
                            } else {
                                if (yearSelected !== 2020) {
                                    setSelectedYearData(yearsData[2020]);
                                    setYearSelected(2020);
                                    setDisplaying(displayYearly);
                                } else {
                                    setSelectedYearData(null);
                                    setYearSelected(null);
                                    setDisplaying(displayNone);
                                }
                            }
                        }}>
                            2020
                        </button>

                        <button className="approved-button" onClick={() => {
                            // Reset Month Data to avoid unexpected behavior
                            setSelectedMonth(null);
                            setSelectedMonthData(null);
                            if (displaying === displayYearly && yearSelected === 2021) {
                                setSelectedYearData(null);
                                setYearSelected(null);
                                setDisplaying(displayAll);
                            } else {
                                if (yearSelected !== 2021) {
                                    setSelectedYearData(yearsData[2021]);
                                    setYearSelected(2021);
                                    setDisplaying(displayYearly);
                                } else {
                                    setSelectedYearData(null);
                                    setYearSelected(null);
                                    setDisplaying(displayNone);
                                }
                            }
                        }}>
                            2021
                        </button>

                        <button className="approved-button" onClick={() => {
                            // Reset Month Data to avoid unexpected behavior
                            setSelectedMonth(null);
                            setSelectedMonthData(null);
                            if (displaying === displayYearly && yearSelected === 2022) {
                                setSelectedYearData(null);
                                setYearSelected(null);
                                setDisplaying(displayAll);
                            } else {
                                if (yearSelected !== 2022) {
                                    setSelectedYearData(null);
                                    setYearSelected(2022);
                                    setDisplaying(displayYearly);
                                } else {
                                    setSelectedYearData(null);
                                    setYearSelected(null);
                                    setDisplaying(displayNone);
                                }
                            }
                        }}>
                            2022
                        </button>


                        <button className="approved-button" onClick={() => {
                            // Reset Month Data to avoid unexpected behavior
                            setSelectedMonth(null);
                            setSelectedMonthData(null);
                            if (displaying === displayYearly && yearSelected === 2023) {
                                setSelectedYearData(null);
                                setYearSelected(null);
                                setDisplaying(displayAll);
                            } else {
                                if (yearSelected !== 2023) {
                                    setSelectedYearData(yearsData[2023]);
                                    setYearSelected(2023);
                                    setDisplaying(displayYearly);
                                } else {
                                    setSelectedYearData(null);
                                    setYearSelected(null);
                                    setDisplaying(displayNone);
                                }
                            }
                        }}>
                            2023
                        </button>

                        <button className="approved-button" onClick={() => {
                            // Reset Month Data to avoid unexpected behavior
                            setSelectedMonth(null);
                            setSelectedMonthData(null);
                            if (displaying === displayYearly && yearSelected === 2024) {
                                setSelectedYearData(null);
                                setYearSelected(null);
                                setDisplaying(displayAll);
                            } else {
                                if (yearSelected !== 2024) {
                                    setSelectedYearData(yearsData[2024]);
                                    setYearSelected(2024);
                                    setDisplaying(displayYearly);
                                } else {
                                    setSelectedYearData(null);
                                    setYearSelected(null);
                                    setDisplaying(displayNone);
                                }
                            }
                        }}>
                            2024
                        </button>
                    </div>

                    {renderSelectedYear()}
                </div>
            </div>
        </>
    )
}