import React from "react";
import { EventSponsorshipData } from "./EventSponsorshipData";
import SponsorshipContainer from "./SponsorshipContainer";

import { Fade } from "react-reveal";

export default function Sponsorships() {
    return (
        <>
            <div className="sponsorship-text">
                <h1 className='header'>Sponsoring Hearts Unite to Give Support</h1>
                <p>Hearts Unite to Give Support offers many different sponsorship oppurtunities at different levels to enable all interested parties in helping towards our goal of assisting those in need.
                </p>
                <p>This page contains our baseline levels for sponsorships and is updated as needed to reflect what oppurtunities are currently available.</p>
                <p>If you're interested in sponsoring an event, please contact us to learn more.</p>

                <h1>Event Sponsorships</h1>
                <h2>Private Companies</h2>

                <div className="tier-section">
                    {EventSponsorshipData.map((item, index) => {
                        return (
                            <SponsorshipContainer title={item.title} amount={item.amount}
                                color={item.color} perks={item.perks}
                            />
                        )
                    })}
                </div>


            </div>
        </>
    )
}