import React from "react";

export default function SponsorshipContainer(props) {
    return (
        <>
        <div className="sponsorship-container">
            <h1 className={props.color}>{props.title}</h1>
            <h2 className="tier-amount">{props.amount}</h2>
            <ul>
                {props.perks.map((item, index) => {
                    return (
                        <li>{item}</li>
                    )
                })}
            </ul>
        </div>
        </>
    )
}