import React from 'react';
import { Helmet } from 'react-helmet';

import ReportForm from '../components/Report/ReportForm';

import { Fade } from 'react-reveal';

import "./ReportStyles.css";

function Report(props) {
    const userData = props.userData;

    React.useEffect(() => {
        document.title = "Report | Hearts Unite";
    }, []);

    const renderHeader = () => {
        if (userData) {
            return (
                <div className="page-header">
                    <h1 className='header'>Having an issue {userData.firstName}? </h1>
                </div>
            )
        } else {
            return (
                <div className="page-header">
                    <h1 className='header'>Having an issue?</h1>
                </div>
            )
        }
    }

    return (
        <>
            <Helmet>
                <title>Report an Issue | Hearts Unite</title>
                {/* Limits User Zoom */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <meta name="description" content="Report any issues relating to Hearts Unite to Give Support." />
                <meta property="og:title" content="Report an Issue | Hearts Unite" />
                <meta property="og:image" content="/images/hugs-logo.png" />
            </Helmet>
            <div className="report">
                <Fade>
                    {renderHeader()}
                </Fade>

                <ReportForm userData={userData} />

            </div>
        </>
    )
}

export default Report;