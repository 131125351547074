import React, { useState } from "react";

import { InputText } from "primereact/inputtext";
import { Fade } from "react-reveal";


export default function AttendingSearch(props) {
    const attending = props.attending;
    const [userInput, setUserInput] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const containsPerson = (person) => {
        for (var i = 0; i < searchResults.length; i++) {
            if (searchResults[i] === person) {
                return true;
            }
        }

        return false;
    }

    const onSearchInput = (event) => {
        // Local Variable to Protect Against Async
        const input = event.target.value.toLowerCase();
        var localResults = searchResults;
        // Update Info
        setUserInput(event.target.value);

        if (input.length > 0) {
            attending.forEach(attendee => {
                const fullName = attendee.firstName.toLowerCase() + " " + attendee.lastName.toLowerCase();
                if (fullName.includes(input) ||
                    attendee.email.toLowerCase().includes(input)) {
                    if (!containsPerson(attendee)) {
                        localResults.push(attendee);
                    }
                } else {
                    localResults = localResults.filter(e => e !== attendee);
                }
            });
            setSearchResults(localResults);
        } else {
            setSearchResults([]);
        }
    }

    const renderAttending = (person) => {
        return (
            <>
                <div className="search-item">
                    <h2>{person.firstName} {person.lastName} | {person.email}</h2>
                    <p>{person.info}</p>
                </div>
            </>)
    }

    const renderResults = () => {
        if (searchResults.length > 0) {
            return (
                <>
                    {searchResults.map((item, index) => {
                        return (
                            <Fade>
                                {renderAttending(item)}
                            </Fade>
                        )

                    })
                    }
                </>
            )
        }
    }

    return (
        <>
            <ul className="applications-list">
                <h1 className="header">Search</h1>
                <div className="search-input">
                    <InputText value={userInput} onInput={onSearchInput}></InputText>
                </div>

                <div>
                    {renderResults()}
                </div>

            </ul>
        </>
    )
}