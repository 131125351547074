import React, { Component } from "react";

import Fade from 'react-reveal/Fade';

class ApplicationOwnerPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = { showConfirmationText: false };
    }

    setOwnerFalse = () => {
        this.props.handleForOther(false);
        this.setPromptCompleted();
    }

    setOwnerTrue = () => {
        this.props.handleForOther(true);
        this.setPromptCompleted();
    }

    setPromptCompleted = () => {
        this.setState({ showConfirmationText: true });
        setTimeout(this.props.handlePromptCompleted, 1000);
    }

    renderButtons() {
        if (!this.state.showConfirmationText) {
            return (
                <ul className="prompt-buttons">

                    <li className="prompt-button">
                        <button onClick={this.setOwnerTrue} className='control-button approved-button'>For Another Person</button>
                    </li>

                    <li className="prompt-button">
                        <button onClick={this.setOwnerFalse} className='control-button approved-button'>For Myself</button>
                    </li>

                </ul>

            )
        } else {
            return (
                <Fade>
                    <h2 className="confirmation-text">Sounds good, let's continue.</h2>
                </Fade>
            );
        }
    }

    render() {
        return (
            <>
                <>
                    <div className="prompt">
                        <h1>Before we Begin</h1>
                        <h3>Are you filling out this application for yourself or on behalf of another?</h3>
                        {this.renderButtons()}
                    </div>
                </>
            </>
        )
    }
}

export default ApplicationOwnerPrompt;