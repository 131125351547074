export const FooterData = [
    {
        title: "Services",
        links: [
            {
                title: "Home",
                url: "/",
                cName: "footer-links",
                icon: "fa-solid fa-house-user"
            },
            {
                title: "Apply for Assistance",
                url: "/apply",
                cName: "footer-links",
                icon: "fa-solid fa-hand-holding-heart"
            },
            {
                title: "Donate",
                url: "/donate",
                cName: "footer-links",
                icon: "fa-solid fa-money-bills",
            },
        ]
    },
    {
        title: "Contact Us",
        links: [
            {
                title: "Contact",
                url: "contact",
                cName: "footer-links",
                icon: "fa-solid fa-envelope"
            },
            {
                title: "Join the Team",
                url: "join",
                cName: "footer-links",
                icon: "fa-solid fa-people-group"
            },
            {
                title: "Report an Issue",
                url: "/report",
                cName: "footer-links",
                icon: "fa-solid fa-wrench"
            },
        ]
    },
    {
        title: "Social Media",
        links: [
            {
                title: "Facebook",
                url: "https://www.facebook.com/people/Hearts-Unite-to-Give-Support/100064615783047/",
                cName: "footer-links",
                icon: "fa-brands fa-facebook"
            },
            // {
            //     title: "Instagram",
            //     url: "/report",
            //     cName: "footer-links",
            //     icon: "fa-brands fa-instagram"
            // },
        ]
    }
]