import React from 'react';
import { Helmet } from 'react-helmet';

import { Fade } from 'react-reveal';

import "./ContactStyles.css";

function Contact(props) {

    return (
        <>
            <Helmet>
                <title>Contact | Hearts Unite</title>
                {/* Limits User Zoom */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <meta name="description" content="Contact information for Hearts Unite to Give Support (HUGS) a nonprofit organization dedicated to helping individuals and families during difficult times." />
                <meta property="og:title" content="Contact | Hearts Unite" />
                <meta property="og:image" content="/images/hugs-logo.png" />
            </Helmet>
            <div className='contact'>
                <Fade>
                    <div className='page-header'>
                        <h1 className='header'>Contact Us</h1>
                    </div>
                </Fade>

                <Fade delay={150}>
                    <div className='contact-body'>
                        <h1 className='header'>We Look Forward to Hearing From You</h1>
                        <p>For issues such as technical problems or issues with applications, we recommend using the report function on the website for fast and easy contact. </p>
                        <p>The following information is best used for business contacts.</p>
                        <p>Thank you for your understanding.</p>
                        <div className='contact-list'>
                            <div className='contact-item'>
                                <h2 className='header'>Email</h2>
                                <h4>hearts-unite@outlook.com</h4>
                            </div>

                            <div className='contact-item'>
                                <h2 className='header'>Phone</h2>
                                <h4>(517) 712-6688</h4>
                            </div>

                            <div className='contact-item'>
                                <h2 className='header'>Mail</h2>
                                <h4>PO Box 5563</h4>
                                <h4>Pahrump, NV 89041</h4>
                            </div>
                        </div>
                    </div>
                </Fade>

            </div>
        </>

    )
}

export default Contact;