import React, { Component } from "react";

class UserInfoBox extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="user-info-box">
                    <h1 className="header">Your Info</h1>
                    <ul className="user-info-list">
                        <li className="user-info-item">
                            <h3 className="header">Name: </h3> <p>{this.props.userData.firstName} {this.props.userData.lastName}</p>
                        </li>

                        <li className="user-info-item">
                            <h3 className="header">Email: </h3> <p>{this.props.userData.email}</p>
                        </li>

                        <li className="user-info-item">
                            <h3 className="header">Applications Submitted: </h3> <p>{this.props.userData.applicationTokens.length}</p>
                        </li>

                        <li className="user-info-item">
                            <h3 className="header">Assistance Recieved: </h3> <p>${this.props.userData.assistanceRecieved}</p>
                        </li>

                    </ul>

                </div>
            </>
        )
    }
}

export default UserInfoBox;