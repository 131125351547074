import React, { Component } from 'react';

class EmployeeNavbar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>

                <div className='control-items'>
                    <button className={ this.props.applications > 0 ? "pending-button" : "approved-button"}
                        onClick={() => { this.props.updateDisplay(this.props.showApplications) }}>
                        Applications ({this.props.applications})</button>

                    <button className={ this.props.events > 0 ? "pending-button" : "approved-button"}
                        onClick={() => { this.props.updateDisplay(this.props.showEvents) }}>
                        Events ({this.props.events})</button>

                        <button className="approved-button"
                        onClick={() => { this.props.updateDisplay(this.props.showStats) }}>
                        Statistics</button>

                    <button className={ this.props.reports > 0 ? "pending-button" : "approved-button"}
                        onClick={() => { this.props.updateDisplay(this.props.showReports) }}>
                        Reports ({this.props.reports})</button>

                    <button className='approved-button'
                        onClick={() => { this.props.updateDisplay(this.props.showUsers) }}>
                        Users</button>
                </div>
            </>
        )
    }
}

export default EmployeeNavbar