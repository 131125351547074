import React, { useState, useLayoutEffect } from 'react';


// Custom Components
import EventSignup from './EventSignup';

// Swiper for Images

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import 'swiper/modules/free-mode/free-mode.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/thumbs/thumbs.min.css';

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

import "./EventsDisplayStyles.css";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function EventsDisplay(props) {
    // Width / Height
    const [width, height] = useWindowSize();

    // States
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [signupShowing, setSignupShowing] = useState(false);

    // Renders

    const renderSignup = () => {
        if (signupShowing) {
            return <EventSignup id={props.event._id} userData={props.userData} instructions={props.event.instructions} />
        }
    }

    const renderSignupButton = () => {
        if (props.event.active) {
            return <button className='approved-button' onClick={() => setSignupShowing(!signupShowing)}>Sign Up for Event</button>
        }
    }

    const renderEventLocation = () => {
        if (props.event.online) {
            return <h3>Online</h3>
        }

        return (
            <>
                <h3>{props.event.streetAddress}</h3>
                <h3>{props.event.streetAddress2}</h3>
                <h3>{props.event.city}</h3>
                <h3>{props.event.state}, {props.event.zip}</h3>
            </>
        )
    }

    const renderImageCarousel = () => {
        if (props.event.images.length > 0) {
            return (
                <div className='carousel'>
                    <div className='main-carousel'>
                        <Swiper
                            style={{
                                "--swiper-navigation-color": "#fff",
                                "--swiper-pagination-color": "#fff",
                            }}
                            spaceBetween={10}
                            slidesPerView={1}
                            navigation={true}
                            loop={true}
                            autoHeight={true}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="swiper-main"
                            breakpoints={{
                                1300: {
                                    slidesPerView: 1,
                                },
                            }}
                        >
                            {props.event.images.map((item, index) => {
                                return (
                                    <SwiperSlide><img src={item.data} alt={props.event.name + " image " + (index + 1)} /></SwiperSlide>
                                )
                            })
                            }
                        </Swiper>
                    </div>

                    <div className='thumbs'>
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            spaceBetween={10}
                            slidesPerView={1}
                            freeMode={true}
                            autoScrollOffset={0}
                            watchSlidesProgress={true}
                            autoHeight={true}
                            loop={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper"
                            breakpoints={{
                                1600: {
                                    slidesPerView: 7,
                                },
                                1000: {
                                    slidesPerView: 6,
                                },
                                850: {
                                    slidesPerView: 5,
                                },
                                650: {
                                    slidesPerView: 4,
                                },
                                480: {
                                    slidesPerView: 3,
                                },
                            }}
                        >
                            {props.event.images.map((item, index) => {
                                return (
                                    <SwiperSlide><img src={item.data} alt={props.event.name + " image " + (index + 1)} /></SwiperSlide>
                                )
                            })
                            }
                        </Swiper>
                    </div>

                </div>
            )
        }
    }

    if (!props) {
        return (<>
            <div className='events-display'>
                <p>Loading...</p>
            </div>
        </>)
    }
    return (
        <>
            <div className='events-display'>
                <h1 className='header'>{props.event.name}</h1>
                <div className='events-display-row'>

                    <div className='events-display-info'>
                        <h1 className='header'>Event Info</h1>
                        <h2 className='event-location header'>Location: </h2>
                        {renderEventLocation()}
                        <h2 className='event-date header'>Date & Time: </h2>
                        <h3>{props.event.time}</h3>
                        <h3>{props.event.date}</h3>

                        <p className='event-description'>{props.event.description}</p>
                        <div className='event-signup-button'>
                            {renderSignupButton()}
                        </div>

                    </div>

                    <div className='events-display-image'>
                        <img src={props.event.mainImage.data} alt='main event image' />
                    </div>
                </div>

                {renderSignup()}

                {renderImageCarousel()}

            </div>
        </>
    )
}

export default EventsDisplay;