import React, { Component } from 'react';

class JoinLoginPrompt extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className='prompt'>
                    <h1 className='header'>We'd Love to Have You</h1>

                    <p>But first, please make a user account.</p>
                    <p>If we accept you, we may need you to have an account for administrative purposes anyway, so it's best to have one set up beforehand!</p>
                    <p>Either click the button in the top right or below to be redirected.</p>

                    <form action='/login'>
                        <button className='approved-button' type='submit'>Signup / Login <i className="fa-solid fa-right-to-bracket" /></button>
                    </form>

                </div>
            </>
        )
    }
}

export default JoinLoginPrompt;